import case1_1main from "../images/case/1/1main.jpg";
import case1_1mainwebp from "../images/case/1/1main.webp";
import case1_1mainavif from "../images/case/1/1main.avif";
import case1_1 from "../images/case/1/1.jpeg";
import case1_1webp from "../images/case/1/1.webp";
import case1_1avif from "../images/case/1/1.avif";
import case1_1mini from "../images/case/1/mini/1.jpeg";
import case1_1miniwebp from "../images/case/1/mini/1.webp";
import case1_1miniavif from "../images/case/1/mini/1.avif";
import case1_2 from "../images/case/1/2.jpeg";
import case1_2webp from "../images/case/1/2.webp";
import case1_2avif from "../images/case/1/2.avif";
import case1_2mini from "../images/case/1/mini/2.jpeg";
import case1_2miniwebp from "../images/case/1/mini/2.webp";
import case1_2miniavif from "../images/case/1/mini/2.avif";
import case1_3 from "../images/case/1/3.jpeg";
import case1_3webp from "../images/case/1/3.webp";
import case1_3avif from "../images/case/1/3.avif";
import case1_3mini from "../images/case/1/mini/3.jpeg";
import case1_3miniwebp from "../images/case/1/mini/3.webp";
import case1_3miniavif from "../images/case/1/mini/3.avif";
import case1_4 from "../images/case/1/4.jpeg";
import case1_4webp from "../images/case/1/4.webp";
import case1_4avif from "../images/case/1/4.avif";
import case1_4mini from "../images/case/1/mini/4.jpeg";
import case1_4miniwebp from "../images/case/1/mini/4.webp";
import case1_4miniavif from "../images/case/1/mini/4.avif";
import case1_5 from "../images/case/1/5.jpeg";
import case1_5webp from "../images/case/1/5.webp";
import case1_5avif from "../images/case/1/5.avif";
import case1_5mini from "../images/case/1/mini/5.jpeg";
import case1_5miniwebp from "../images/case/1/mini/5.webp";
import case1_5miniavif from "../images/case/1/mini/5.avif";
import case1_6 from "../images/case/1/6.jpeg";
import case1_6webp from "../images/case/1/6.webp";
import case1_6avif from "../images/case/1/6.avif";
import case1_61mini from "../images/case/1/mini/6.jpeg";
import case1_6miniwebp from "../images/case/1/mini/6.webp";
import case1_6miniavif from "../images/case/1/mini/6.avif";
import case1_7 from "../images/case/1/7.jpeg";
import case1_7webp from "../images/case/1/7.webp";
import case1_7avif from "../images/case/1/7.avif";
import case1_7mini from "../images/case/1/mini/7.jpeg";
import case1_7miniwebp from "../images/case/1/mini/7.webp";
import case1_7miniavif from "../images/case/1/mini/7.avif";
import case1_8 from "../images/case/1/8.jpeg";
import case1_8webp from "../images/case/1/8.webp";
import case1_8avif from "../images/case/1/8.avif";
import case1_8mini from "../images/case/1/mini/8.jpeg";
import case1_8miniwebp from "../images/case/1/mini/8.webp";
import case1_8miniavif from "../images/case/1/mini/8.avif";
import case1_9 from "../images/case/1/9.jpeg";
import case1_9webp from "../images/case/1/9.webp";
import case1_9avif from "../images/case/1/9.avif";
import case1_9mini from "../images/case/1/mini/9.jpeg";
import case1_9miniwebp from "../images/case/1/mini/9.webp";
import case1_9miniavif from "../images/case/1/mini/9.avif";
import case1_10 from "../images/case/1/10.jpeg";
import case1_10webp from "../images/case/1/10.webp";
import case1_10avif from "../images/case/1/10.avif";
import case1_10mini from "../images/case/1/mini/10.jpeg";
import case1_10miniwebp from "../images/case/1/mini/10.webp";
import case1_10miniavif from "../images/case/1/mini/10.avif";
import case1_11 from "../images/case/1/11.jpeg";
import case1_11webp from "../images/case/1/11.webp";
import case1_11avif from "../images/case/1/11.avif";
import case1_11mini from "../images/case/1/mini/11.jpeg";
import case1_11miniwebp from "../images/case/1/mini/11.webp";
import case1_11miniavif from "../images/case/1/mini/11.avif";
import case1_12 from "../images/case/1/12.jpeg";
import case1_12webp from "../images/case/1/12.webp";
import case1_12avif from "../images/case/1/12.avif";
import case1_12mini from "../images/case/1/mini/12.jpeg";
import case1_12miniwebp from "../images/case/1/mini/12.webp";
import case1_12miniavif from "../images/case/1/mini/12.avif";
import case1_13 from "../images/case/1/13.jpeg";
import case1_13webp from "../images/case/1/13.webp";
import case1_13avif from "../images/case/1/13.avif";
import case1_13mini from "../images/case/1/mini/13.jpeg";
import case1_13miniwebp from "../images/case/1/mini/13.webp";
import case1_13miniavif from "../images/case/1/mini/13.avif";
import case1_14 from "../images/case/1/14.jpeg";
import case1_14webp from "../images/case/1/14.webp";
import case1_14avif from "../images/case/1/14.avif";
import case1_14mini from "../images/case/1/mini/14.jpeg";
import case1_14miniwebp from "../images/case/1/mini/14.webp";
import case1_14miniavif from "../images/case/1/mini/14.avif";
import case2_1main from "../images/case/2/1main.jpg";
import case2_1mainwebp from "../images/case/2/1main.webp";
import case2_1mainavif from "../images/case/2/1main.avif";
import case2_1 from "../images/case/2/1.jpeg";
import case2_1webp from "../images/case/2/1.webp";
import case2_1avif from "../images/case/2/1.avif";
import case2_1mini from "../images/case/2/mini/1.jpeg";
import case2_1miniwebp from "../images/case/2/mini/1.webp";
import case2_1miniavif from "../images/case/2/mini/1.avif";
import case2_2 from "../images/case/2/2.jpeg";
import case2_2webp from "../images/case/2/2.webp";
import case2_2avif from "../images/case/2/2.avif";
import case2_2mini from "../images/case/2/mini/2.jpeg";
import case2_2miniwebp from "../images/case/2/mini/2.webp";
import case2_2miniavif from "../images/case/2/mini/2.avif";
import case2_3 from "../images/case/2/3.jpeg";
import case2_3webp from "../images/case/2/3.webp";
import case2_3avif from "../images/case/2/3.avif";
import case2_3mini from "../images/case/2/mini/3.jpeg";
import case2_3miniwebp from "../images/case/2/mini/3.webp";
import case2_3miniavif from "../images/case/2/mini/3.avif";
import case2_4 from "../images/case/2/4.jpeg";
import case2_4webp from "../images/case/2/4.webp";
import case2_4avif from "../images/case/2/4.avif";
import case2_4mini from "../images/case/2/mini/4.jpeg";
import case2_4miniwebp from "../images/case/2/mini/4.webp";
import case2_4miniavif from "../images/case/2/mini/4.avif";
import case2_5 from "../images/case/2/5.jpeg";
import case2_5webp from "../images/case/2/5.webp";
import case2_5avif from "../images/case/2/5.avif";
import case2_5mini from "../images/case/2/mini/5.jpeg";
import case2_5miniwebp from "../images/case/2/mini/5.webp";
import case2_5miniavif from "../images/case/2/mini/5.avif";
import case2_6 from "../images/case/2/6.jpeg";
import case2_6webp from "../images/case/2/6.webp";
import case2_6avif from "../images/case/2/6.avif";
import case2_6mini from "../images/case/2/mini/6.jpeg";
import case2_6miniwebp from "../images/case/2/mini/6.webp";
import case2_6miniavif from "../images/case/2/mini/6.avif";
import case2_7 from "../images/case/2/7.jpeg";
import case2_7webp from "../images/case/2/7.webp";
import case2_7avif from "../images/case/2/7.avif";
import case2_7mini from "../images/case/2/mini/7.jpeg";
import case2_7miniwebp from "../images/case/2/mini/7.webp";
import case2_7miniavif from "../images/case/2/mini/7.avif";
import case2_8 from "../images/case/2/8.jpeg";
import case2_8webp from "../images/case/2/8.webp";
import case2_8avif from "../images/case/2/8.avif";
import case2_8mini from "../images/case/2/mini/8.jpeg";
import case2_8miniwebp from "../images/case/2/mini/8.webp";
import case2_8miniavif from "../images/case/2/mini/8.avif";
import case2_9 from "../images/case/2/9.jpeg";
import case2_9webp from "../images/case/2/9.webp";
import case2_9avif from "../images/case/2/9.avif";
import case2_9mini from "../images/case/2/mini/9.jpeg";
import case2_9miniwebp from "../images/case/2/mini/9.webp";
import case2_9miniavif from "../images/case/2/mini/9.avif";
import case2_10 from "../images/case/2/10.jpeg";
import case2_10webp from "../images/case/2/10.webp";
import case2_10avif from "../images/case/2/10.avif";
import case2_10mini from "../images/case/2/mini/10.jpeg";
import case2_10miniwebp from "../images/case/2/mini/10.webp";
import case2_10miniavif from "../images/case/2/mini/10.avif";
import case2_11 from "../images/case/2/11.jpeg";
import case2_11webp from "../images/case/2/11.webp";
import case2_11avif from "../images/case/2/11.avif";
import case2_11mini from "../images/case/2/mini/11.jpeg";
import case2_11miniwebp from "../images/case/2/mini/11.webp";
import case2_11miniavif from "../images/case/2/mini/11.avif";
import case2_12 from "../images/case/2/12.jpeg";
import case2_12webp from "../images/case/2/12.webp";
import case2_12avif from "../images/case/2/12.avif";
import case2_12mini from "../images/case/2/mini/12.jpeg";
import case2_12miniwebp from "../images/case/2/mini/12.webp";
import case2_12miniavif from "../images/case/2/mini/12.avif";
import case2_13 from "../images/case/2/13.jpeg";
import case2_13webp from "../images/case/2/13.webp";
import case2_13avif from "../images/case/2/13.avif";
import case2_13mini from "../images/case/2/mini/13.jpeg";
import case2_13miniwebp from "../images/case/2/mini/13.webp";
import case2_13miniavif from "../images/case/2/mini/13.avif";
import case2_14 from "../images/case/2/14.jpeg";
import case2_14webp from "../images/case/2/14.webp";
import case2_14avif from "../images/case/2/14.avif";
import case2_14mini from "../images/case/2/mini/14.jpeg";
import case2_14miniwebp from "../images/case/2/mini/14.webp";
import case2_14miniavif from "../images/case/2/mini/14.avif";
import case2_15 from "../images/case/2/15.jpeg";
import case2_15webp from "../images/case/2/15.webp";
import case2_15avif from "../images/case/2/15.avif";
import case2_15mini from "../images/case/2/mini/15.jpeg";
import case2_15miniwebp from "../images/case/2/mini/15.webp";
import case2_15miniavif from "../images/case/2/mini/15.avif";
import case2_16 from "../images/case/2/16.jpeg";
import case2_16webp from "../images/case/2/16.webp";
import case2_16avif from "../images/case/2/16.avif";
import case2_16mini from "../images/case/2/mini/16.jpeg";
import case2_16miniwebp from "../images/case/2/mini/16.webp";
import case2_16miniavif from "../images/case/2/mini/16.avif";
import case2_17 from "../images/case/2/17.jpeg";
import case2_17webp from "../images/case/2/17.webp";
import case2_17avif from "../images/case/2/17.avif";
import case2_17mini from "../images/case/2/mini/17.jpeg";
import case2_17miniwebp from "../images/case/2/mini/17.webp";
import case2_17miniavif from "../images/case/2/mini/17.avif";
import case2_18 from "../images/case/2/18.jpeg";
import case2_18webp from "../images/case/2/18.webp";
import case2_18avif from "../images/case/2/18.avif";
import case2_18mini from "../images/case/2/mini/18.jpeg";
import case2_18miniwebp from "../images/case/2/mini/18.webp";
import case2_18miniavif from "../images/case/2/mini/18.avif";
import case2_19 from "../images/case/2/19.jpeg";
import case2_19webp from "../images/case/2/19.webp";
import case2_19avif from "../images/case/2/19.avif";
import case2_19mini from "../images/case/2/mini/19.jpeg";
import case2_19miniwebp from "../images/case/2/mini/19.webp";
import case2_19miniavif from "../images/case/2/mini/19.avif";
import case2_20 from "../images/case/2/20.jpeg";
import case2_20webp from "../images/case/2/20.webp";
import case2_20avif from "../images/case/2/20.avif";
import case2_20mini from "../images/case/2/mini/20.jpeg";
import case2_20miniwebp from "../images/case/2/mini/20.webp";
import case2_20miniavif from "../images/case/2/mini/20.avif";
import case2_21 from "../images/case/2/21.jpeg";
import case2_21webp from "../images/case/2/21.webp";
import case2_21avif from "../images/case/2/21.avif";
import case2_21mini from "../images/case/2/mini/21.jpeg";
import case2_21miniwebp from "../images/case/2/mini/21.webp";
import case2_21miniavif from "../images/case/2/mini/21.avif";
import case2_22 from "../images/case/2/22.jpeg";
import case2_22webp from "../images/case/2/22.webp";
import case2_22avif from "../images/case/2/22.avif";
import case2_22mini from "../images/case/2/mini/22.jpeg";
import case2_22miniwebp from "../images/case/2/mini/22.webp";
import case2_22miniavif from "../images/case/2/mini/22.avif";
import case3_1main from "../images/case/3/1main.jpg";
import case3_1mainwebp from "../images/case/3/1main.webp";
import case3_1mainavif from "../images/case/3/1main.avif";
import case3_1 from "../images/case/3/1.jpeg";
import case3_1webp from "../images/case/3/1.webp";
import case3_1avif from "../images/case/3/1.avif";
import case3_1mini from "../images/case/3/mini/1.jpeg";
import case3_1miniwebp from "../images/case/3/mini/1.webp";
import case3_1miniavif from "../images/case/3/mini/1.avif";
import case3_2 from "../images/case/3/2.jpeg";
import case3_2webp from "../images/case/3/2.webp";
import case3_2avif from "../images/case/3/2.avif";
import case3_2mini from "../images/case/3/mini/2.jpeg";
import case3_2miniwebp from "../images/case/3/mini/2.webp";
import case3_2miniavif from "../images/case/3/mini/2.avif";
import case3_3 from "../images/case/3/3.jpeg";
import case3_3webp from "../images/case/3/3.webp";
import case3_3avif from "../images/case/3/3.avif";
import case3_3mini from "../images/case/3/mini/3.jpeg";
import case3_3miniwebp from "../images/case/3/mini/3.webp";
import case3_3miniavif from "../images/case/3/mini/3.avif";
import case3_4 from "../images/case/3/4.jpeg";
import case3_4webp from "../images/case/3/4.webp";
import case3_4avif from "../images/case/3/4.avif";
import case3_4mini from "../images/case/3/mini/4.jpeg";
import case3_4miniwebp from "../images/case/3/mini/4.webp";
import case3_4miniavif from "../images/case/3/mini/4.avif";
import case3_5 from "../images/case/3/5.jpeg";
import case3_5webp from "../images/case/3/5.webp";
import case3_5avif from "../images/case/3/5.avif";
import case3_5mini from "../images/case/3/mini/5.jpeg";
import case3_5miniwebp from "../images/case/3/mini/5.webp";
import case3_5miniavif from "../images/case/3/mini/5.avif";
import case3_6 from "../images/case/3/6.jpeg";
import case3_6webp from "../images/case/3/6.webp";
import case3_6avif from "../images/case/3/6.avif";
import case3_6mini from "../images/case/3/mini/6.jpeg";
import case3_6miniwebp from "../images/case/3/mini/6.webp";
import case3_6miniavif from "../images/case/3/mini/6.avif";
import case3_7 from "../images/case/3/7.jpeg";
import case3_7webp from "../images/case/3/7.webp";
import case3_7avif from "../images/case/3/7.avif";
import case3_7mini from "../images/case/3/mini/7.jpeg";
import case3_7miniwebp from "../images/case/3/mini/7.webp";
import case3_7miniavif from "../images/case/3/mini/7.avif";
import case3_8 from "../images/case/3/8.jpeg";
import case3_8webp from "../images/case/3/8.webp";
import case3_8avif from "../images/case/3/8.avif";
import case3_8mini from "../images/case/3/mini/8.jpeg";
import case3_8miniwebp from "../images/case/3/mini/8.webp";
import case3_8miniavif from "../images/case/3/mini/8.avif";
import case3_9 from "../images/case/3/9.jpeg";
import case3_9webp from "../images/case/3/9.webp";
import case3_9avif from "../images/case/3/9.avif";
import case3_9mini from "../images/case/3/mini/9.jpeg";
import case3_9miniwebp from "../images/case/3/mini/9.webp";
import case3_9miniavif from "../images/case/3/mini/9.avif";
import case3_10 from "../images/case/3/10.jpeg";
import case3_10webp from "../images/case/3/10.webp";
import case3_10avif from "../images/case/3/10.avif";
import case3_10mini from "../images/case/3/mini/10.jpeg";
import case3_10miniwebp from "../images/case/3/mini/10.webp";
import case3_10miniavif from "../images/case/3/mini/10.avif";
import case3_11 from "../images/case/3/11.jpeg";
import case3_11webp from "../images/case/3/11.webp";
import case3_11avif from "../images/case/3/11.avif";
import case3_11mini from "../images/case/3/mini/11.jpeg";
import case3_11miniwebp from "../images/case/3/mini/11.webp";
import case3_11miniavif from "../images/case/3/mini/11.avif";
import case3_12 from "../images/case/3/12.jpeg";
import case3_12webp from "../images/case/3/12.webp";
import case3_12avif from "../images/case/3/12.avif";
import case3_12mini from "../images/case/3/mini/12.jpeg";
import case3_12miniwebp from "../images/case/3/mini/12.webp";
import case3_12miniavif from "../images/case/3/mini/12.avif";
import case3_13 from "../images/case/3/13.jpeg";
import case3_13webp from "../images/case/3/13.webp";
import case3_13avif from "../images/case/3/13.avif";
import case3_13mini from "../images/case/3/mini/13.jpeg";
import case3_13miniwebp from "../images/case/3/mini/13.webp";
import case3_13miniavif from "../images/case/3/mini/13.avif";
import case3_14 from "../images/case/3/14.jpeg";
import case3_14webp from "../images/case/3/14.webp";
import case3_14avif from "../images/case/3/14.avif";
import case3_14mini from "../images/case/3/mini/14.jpeg";
import case3_14miniwebp from "../images/case/3/mini/14.webp";
import case3_14miniavif from "../images/case/3/mini/14.avif";
import case3_15 from "../images/case/3/15.jpeg";
import case3_15webp from "../images/case/3/15.webp";
import case3_15avif from "../images/case/3/15.avif";
import case3_15mini from "../images/case/3/mini/15.jpeg";
import case3_15miniwebp from "../images/case/3/mini/15.webp";
import case3_15miniavif from "../images/case/3/mini/15.avif";
import case3_16 from "../images/case/3/16.jpeg";
import case3_16webp from "../images/case/3/16.webp";
import case3_16avif from "../images/case/3/16.avif";
import case3_16mini from "../images/case/3/mini/16.jpeg";
import case3_16miniwebp from "../images/case/3/mini/16.webp";
import case3_16miniavif from "../images/case/3/mini/16.avif";
import case3_17 from "../images/case/3/17.jpeg";
import case3_17webp from "../images/case/3/17.webp";
import case3_17avif from "../images/case/3/17.avif";
import case3_17mini from "../images/case/3/mini/17.jpeg";
import case3_17miniwebp from "../images/case/3/mini/17.webp";
import case3_17miniavif from "../images/case/3/mini/17.avif";
import case3_18 from "../images/case/3/18.jpeg";
import case3_18webp from "../images/case/3/18.webp";
import case3_18avif from "../images/case/3/18.avif";
import case3_18mini from "../images/case/3/mini/18.jpeg";
import case3_18miniwebp from "../images/case/3/mini/18.webp";
import case3_18miniavif from "../images/case/3/mini/18.avif";
import case3_19 from "../images/case/3/19.jpeg";
import case3_19webp from "../images/case/3/19.webp";
import case3_19avif from "../images/case/3/19.avif";
import case3_19mini from "../images/case/3/mini/19.jpeg";
import case3_19miniwebp from "../images/case/3/mini/19.webp";
import case3_19miniavif from "../images/case/3/mini/19.avif";
import case3_20 from "../images/case/3/20.jpeg";
import case3_20webp from "../images/case/3/20.webp";
import case3_20avif from "../images/case/3/20.avif";
import case3_20mini from "../images/case/3/mini/20.jpeg";
import case3_20miniwebp from "../images/case/3/mini/20.webp";
import case3_20miniavif from "../images/case/3/mini/20.avif";
import case3_21 from "../images/case/3/21.jpeg";
import case3_21webp from "../images/case/3/21.webp";
import case3_21avif from "../images/case/3/21.avif";
import case3_21mini from "../images/case/3/mini/21.jpeg";
import case3_21miniwebp from "../images/case/3/mini/21.webp";
import case3_21miniavif from "../images/case/3/mini/21.avif";
import case3_22 from "../images/case/3/22.jpeg";
import case3_22webp from "../images/case/3/22.webp";
import case3_22avif from "../images/case/3/2.avif";
import case3_22mini from "../images/case/3/mini/22.jpeg";
import case3_22miniwebp from "../images/case/3/mini/22.webp";
import case3_22miniavif from "../images/case/3/mini/22.avif";
import case3_23 from "../images/case/3/23.jpeg";
import case3_23webp from "../images/case/3/23.webp";
import case3_23avif from "../images/case/3/23.avif";
import case3_23mini from "../images/case/3/mini/23.jpeg";
import case3_23miniwebp from "../images/case/3/mini/23.webp";
import case3_23miniavif from "../images/case/3/mini/23.avif";
import case3_24 from "../images/case/3/24.jpeg";
import case3_24webp from "../images/case/3/24.webp";
import case3_24avif from "../images/case/3/24.avif";
import case3_24mini from "../images/case/3/mini/24.jpeg";
import case3_24miniwebp from "../images/case/3/mini/24.webp";
import case3_24miniavif from "../images/case/3/mini/24.avif";
import case3_25 from "../images/case/3/25.jpeg";
import case3_25webp from "../images/case/3/25.webp";
import case3_25avif from "../images/case/3/25.avif";
import case3_25mini from "../images/case/3/mini/25.jpeg";
import case3_25miniwebp from "../images/case/3/mini/25.webp";
import case3_25miniavif from "../images/case/3/mini/25.avif";
import case4_1main from "../images/case/4/1main.jpg";
import case4_1mainwebp from "../images/case/4/1main.webp";
import case4_1mainavif from "../images/case/4/1main.avif";
import case4_1 from "../images/case/4/1.jpeg";
import case4_1webp from "../images/case/4/1.webp";
import case4_1avif from "../images/case/4/1.avif";
import case4_1mini from "../images/case/4/mini/1.jpeg";
import case4_1miniwebp from "../images/case/4/mini/1.webp";
import case4_1miniavif from "../images/case/4/mini/1.avif";
import case4_2 from "../images/case/4/2.jpeg";
import case4_2webp from "../images/case/4/2.webp";
import case4_2avif from "../images/case/4/2.avif";
import case4_2mini from "../images/case/4/mini/2.jpeg";
import case4_2miniwebp from "../images/case/4/mini/2.webp";
import case4_2miniavif from "../images/case/4/mini/2.avif";
import case4_3 from "../images/case/4/3.jpeg";
import case4_3webp from "../images/case/4/3.webp";
import case4_3avif from "../images/case/4/3.avif";
import case4_3mini from "../images/case/4/mini/3.jpeg";
import case4_3miniwebp from "../images/case/4/mini/3.webp";
import case4_3miniavif from "../images/case/4/mini/3.avif";
import case4_4 from "../images/case/4/4.jpeg";
import case4_4webp from "../images/case/4/4.webp";
import case4_4avif from "../images/case/4/4.avif";
import case4_4mini from "../images/case/4/mini/4.jpeg";
import case4_4miniwebp from "../images/case/4/mini/4.webp";
import case4_4miniavif from "../images/case/4/mini/4.avif";
import case4_5 from "../images/case/4/5.jpeg";
import case4_5webp from "../images/case/4/5.webp";
import case4_5avif from "../images/case/4/5.avif";
import case4_5mini from "../images/case/4/mini/5.jpeg";
import case4_5miniwebp from "../images/case/4/mini/5.webp";
import case4_5miniavif from "../images/case/4/mini/5.avif";
import case4_6 from "../images/case/4/6.jpeg";
import case4_6webp from "../images/case/4/6.webp";
import case4_6avif from "../images/case/4/6.avif";
import case4_6mini from "../images/case/4/mini/6.jpeg";
import case4_6miniwebp from "../images/case/4/mini/6.webp";
import case4_6miniavif from "../images/case/4/mini/6.avif";
import case4_7 from "../images/case/4/7.jpeg";
import case4_7webp from "../images/case/4/7.webp";
import case4_7avif from "../images/case/4/7.avif";
import case4_7mini from "../images/case/4/mini/7.jpeg";
import case4_7miniwebp from "../images/case/4/mini/7.webp";
import case4_7miniavif from "../images/case/4/mini/7.avif";
import case4_8 from "../images/case/4/8.jpeg";
import case4_8webp from "../images/case/4/8.webp";
import case4_8avif from "../images/case/4/8.avif";
import case4_8mini from "../images/case/4/mini/8.jpeg";
import case4_8miniwebp from "../images/case/4/mini/8.webp";
import case4_8miniavif from "../images/case/4/mini/8.avif";
import case4_9 from "../images/case/4/9.jpeg";
import case4_9webp from "../images/case/4/9.webp";
import case4_9avif from "../images/case/4/9.avif";
import case4_9mini from "../images/case/4/mini/9.jpeg";
import case4_9miniwebp from "../images/case/4/mini/9.webp";
import case4_9miniavif from "../images/case/4/mini/9.avif";
import case4_10 from "../images/case/4/10.jpeg";
import case4_10webp from "../images/case/4/10.webp";
import case4_10avif from "../images/case/4/10.avif";
import case4_10mini from "../images/case/4/mini/10.jpeg";
import case4_10miniwebp from "../images/case/4/mini/10.webp";
import case4_10miniavif from "../images/case/4/mini/10.avif";
import case4_11 from "../images/case/4/11.jpeg";
import case4_11webp from "../images/case/4/11.webp";
import case4_11avif from "../images/case/4/11.avif";
import case4_11mini from "../images/case/4/mini/11.jpeg";
import case4_11miniwebp from "../images/case/4/mini/11.webp";
import case4_11miniavif from "../images/case/4/mini/11.avif";
import case4_12 from "../images/case/4/12.jpeg";
import case4_12webp from "../images/case/4/12.webp";
import case4_12avif from "../images/case/4/12.avif";
import case4_12mini from "../images/case/4/mini/12.jpeg";
import case4_12miniwebp from "../images/case/4/mini/12.webp";
import case4_12miniavif from "../images/case/4/mini/12.avif";
import case4_13 from "../images/case/4/13.jpeg";
import case4_13webp from "../images/case/4/13.webp";
import case4_13avif from "../images/case/4/13.avif";
import case4_13mini from "../images/case/4/mini/13.jpeg";
import case4_13miniwebp from "../images/case/4/mini/13.webp";
import case4_13miniavif from "../images/case/4/mini/13.avif";
import case4_14 from "../images/case/4/14.jpeg";
import case4_14webp from "../images/case/4/14.webp";
import case4_14avif from "../images/case/4/14.avif";
import case4_14mini from "../images/case/4/mini/14.jpeg";
import case4_14miniwebp from "../images/case/4/mini/14.webp";
import case4_14miniavif from "../images/case/4/mini/14.avif";
import case4_15 from "../images/case/4/15.jpeg";
import case4_15webp from "../images/case/4/15.webp";
import case4_15avif from "../images/case/4/15.avif";
import case4_15mini from "../images/case/4/mini/15.jpeg";
import case4_15miniwebp from "../images/case/4/mini/15.webp";
import case4_15miniavif from "../images/case/4/mini/15.avif";
import case4_16 from "../images/case/4/16.jpeg";
import case4_16webp from "../images/case/4/16.webp";
import case4_16avif from "../images/case/4/16.avif";
import case4_16mini from "../images/case/4/mini/16.jpeg";
import case4_16miniwebp from "../images/case/4/mini/16.webp";
import case4_16miniavif from "../images/case/4/mini/16.avif";
import case4_17 from "../images/case/4/17.jpeg";
import case4_17webp from "../images/case/4/17.webp";
import case4_17avif from "../images/case/4/17.avif";
import case4_17mini from "../images/case/4/mini/17.jpeg";
import case4_17miniwebp from "../images/case/4/mini/17.webp";
import case4_17miniavif from "../images/case/4/mini/17.avif";
import case4_18 from "../images/case/4/18.jpeg";
import case4_18webp from "../images/case/4/18.webp";
import case4_18avif from "../images/case/4/18.avif";
import case4_18mini from "../images/case/4/mini/18.jpeg";
import case4_18miniwebp from "../images/case/4/mini/18.webp";
import case4_18miniavif from "../images/case/4/mini/18.avif";
import case4_19 from "../images/case/4/19.jpeg";
import case4_19webp from "../images/case/4/19.webp";
import case4_19avif from "../images/case/4/19.avif";
import case4_19mini from "../images/case/4/mini/19.jpeg";
import case4_19miniwebp from "../images/case/4/mini/19.webp";
import case4_19miniavif from "../images/case/4/mini/19.avif";
import case4_20 from "../images/case/4/20.jpeg";
import case4_20webp from "../images/case/4/20.webp";
import case4_20avif from "../images/case/4/20.avif";
import case4_20mini from "../images/case/4/mini/20.jpeg";
import case4_20miniwebp from "../images/case/4/mini/20.webp";
import case4_20miniavif from "../images/case/4/mini/20.avif";
import case4_21 from "../images/case/4/21.jpeg";
import case4_21webp from "../images/case/4/21.webp";
import case4_21avif from "../images/case/4/21.avif";
import case4_21mini from "../images/case/4/mini/21.jpeg";
import case4_21miniwebp from "../images/case/4/mini/21.webp";
import case4_21miniavif from "../images/case/4/mini/21.avif";
import case4_22 from "../images/case/4/22.jpeg";
import case4_22webp from "../images/case/4/22.webp";
import case4_22avif from "../images/case/4/22.avif";
import case4_22mini from "../images/case/4/mini/22.jpeg";
import case4_22miniwebp from "../images/case/4/mini/22.webp";
import case4_22miniavif from "../images/case/4/mini/22.avif";
import case4_23 from "../images/case/4/23.jpeg";
import case4_23webp from "../images/case/4/23.webp";
import case4_23avif from "../images/case/4/23.avif";
import case4_23mini from "../images/case/4/mini/23.jpeg";
import case4_23miniwebp from "../images/case/4/mini/23.webp";
import case4_23miniavif from "../images/case/4/mini/23.avif";
import case4_24 from "../images/case/4/24.jpeg";
import case4_24webp from "../images/case/4/24.webp";
import case4_24avif from "../images/case/4/24.avif";
import case4_24mini from "../images/case/4/mini/24.jpeg";
import case4_24miniwebp from "../images/case/4/mini/24.webp";
import case4_24miniavif from "../images/case/4/mini/24.avif";
import case4_25 from "../images/case/4/25.jpeg";
import case4_25webp from "../images/case/4/25.webp";
import case4_25avif from "../images/case/4/25.avif";
import case4_25mini from "../images/case/4/mini/25.jpeg";
import case4_25miniwebp from "../images/case/4/mini/25.webp";
import case4_25miniavif from "../images/case/4/mini/25.avif";
import case4_26 from "../images/case/4/26.jpeg";
import case4_26webp from "../images/case/4/26.webp";
import case4_26avif from "../images/case/4/26.avif";
import case4_26mini from "../images/case/4/mini/26.jpeg";
import case4_26miniwebp from "../images/case/4/mini/26.webp";
import case4_26miniavif from "../images/case/4/mini/26.avif";
import case4_27 from "../images/case/4/27.jpeg";
import case4_27webp from "../images/case/4/27.webp";
import case4_27avif from "../images/case/4/27.avif";
import case4_27mini from "../images/case/4/mini/27.jpeg";
import case4_27miniwebp from "../images/case/4/mini/27.webp";
import case4_27miniavif from "../images/case/4/mini/27.avif";
import case4_28 from "../images/case/4/28.jpeg";
import case4_28webp from "../images/case/4/28.webp";
import case4_28avif from "../images/case/4/28.avif";
import case4_28mini from "../images/case/4/mini/28.jpeg";
import case4_28miniwebp from "../images/case/4/mini/28.webp";
import case4_28miniavif from "../images/case/4/mini/28.avif";
import case4_29 from "../images/case/4/29.jpeg";
import case4_29webp from "../images/case/4/29.webp";
import case4_29avif from "../images/case/4/29.avif";
import case4_29mini from "../images/case/4/mini/29.jpeg";
import case4_29miniwebp from "../images/case/4/mini/29.webp";
import case4_29miniavif from "../images/case/4/mini/29.avif";
import case4_30 from "../images/case/4/30.jpeg";
import case4_30webp from "../images/case/4/30.webp";
import case4_30avif from "../images/case/4/30.avif";
import case4_30mini from "../images/case/4/mini/30.jpeg";
import case4_30miniwebp from "../images/case/4/mini/30.webp";
import case4_30miniavif from "../images/case/4/mini/30.avif";
import case4_31 from "../images/case/4/31.jpeg";
import case4_31webp from "../images/case/4/31.webp";
import case4_31avif from "../images/case/4/31.avif";
import case4_31mini from "../images/case/4/mini/31.jpeg";
import case4_31miniwebp from "../images/case/4/mini/31.webp";
import case4_31miniavif from "../images/case/4/mini/31.avif";
import case4_32 from "../images/case/4/32.jpeg";
import case4_32webp from "../images/case/4/32.webp";
import case4_32avif from "../images/case/4/32.avif";
import case4_32mini from "../images/case/4/mini/32.jpeg";
import case4_32miniwebp from "../images/case/4/mini/32.webp";
import case4_32miniavif from "../images/case/4/mini/32.avif";
import case4_33 from "../images/case/4/33.jpeg";
import case4_33webp from "../images/case/4/33.webp";
import case4_33avif from "../images/case/4/33.avif";
import case4_33mini from "../images/case/4/mini/33.jpeg";
import case4_33miniwebp from "../images/case/4/mini/33.webp";
import case4_33miniavif from "../images/case/4/mini/33.avif";
import case5_1main from "../images/case/5/1main.jpg";
import case5_1mainwebp from "../images/case/5/1main.webp";
import case5_1mainavif from "../images/case/5/1main.avif";
import case5_1 from "../images/case/5/1.jpeg";
import case5_1webp from "../images/case/5/1.webp";
import case5_1avif from "../images/case/5/1.avif";
import case5_1mini from "../images/case/5/mini/1.jpeg";
import case5_1miniwebp from "../images/case/5/mini/1.webp";
import case5_1miniavif from "../images/case/5/mini/1.avif";
import case5_2 from "../images/case/5/2.jpeg";
import case5_2webp from "../images/case/5/2.webp";
import case5_2avif from "../images/case/5/2.avif";
import case5_2mini from "../images/case/5/mini/2.jpeg";
import case5_2miniwebp from "../images/case/5/mini/2.webp";
import case5_2miniavif from "../images/case/5/mini/2.avif";
import case5_3 from "../images/case/5/3.jpeg";
import case5_3webp from "../images/case/5/3.webp";
import case5_3avif from "../images/case/5/3.avif";
import case5_3mini from "../images/case/5/mini/3.jpeg";
import case5_3miniwebp from "../images/case/5/mini/3.webp";
import case5_3miniavif from "../images/case/5/mini/3.avif";
import case5_4 from "../images/case/5/4.jpeg";
import case5_4webp from "../images/case/5/4.webp";
import case5_4avif from "../images/case/5/4.avif";
import case5_4mini from "../images/case/5/mini/4.jpeg";
import case5_4miniwebp from "../images/case/5/mini/4.webp";
import case5_4miniavif from "../images/case/5/mini/4.avif";
import case5_5 from "../images/case/5/5.jpeg";
import case5_5webp from "../images/case/5/5.webp";
import case5_5avif from "../images/case/5/5.avif";
import case5_5mini from "../images/case/5/mini/5.jpeg";
import case5_5miniwebp from "../images/case/5/mini/5.webp";
import case5_5miniavif from "../images/case/5/mini/5.avif";
import case5_6 from "../images/case/5/6.jpeg";
import case5_6webp from "../images/case/5/6.webp";
import case5_6avif from "../images/case/5/6.avif";
import case5_6mini from "../images/case/5/mini/6.jpeg";
import case5_6miniwebp from "../images/case/5/mini/6.webp";
import case5_6miniavif from "../images/case/5/mini/6.avif";
import case5_7 from "../images/case/5/7.jpeg";
import case5_7webp from "../images/case/5/7.webp";
import case5_7avif from "../images/case/5/7.avif";
import case5_7mini from "../images/case/5/mini/7.jpeg";
import case5_7miniwebp from "../images/case/5/mini/7.webp";
import case5_7miniavif from "../images/case/5/mini/7.avif";
import case5_8 from "../images/case/5/8.jpeg";
import case5_8webp from "../images/case/5/8.webp";
import case5_8avif from "../images/case/5/8.avif";
import case5_8mini from "../images/case/5/mini/8.jpeg";
import case5_8miniwebp from "../images/case/5/mini/8.webp";
import case5_8miniavif from "../images/case/5/mini/8.avif";
import case5_9 from "../images/case/5/9.jpeg";
import case5_9webp from "../images/case/5/9.webp";
import case5_9avif from "../images/case/5/9.avif";
import case5_9mini from "../images/case/5/mini/9.jpeg";
import case5_9miniwebp from "../images/case/5/mini/9.webp";
import case5_9miniavif from "../images/case/5/mini/9.avif";
import case5_10 from "../images/case/5/10.jpeg";
import case5_10webp from "../images/case/5/10.webp";
import case5_10avif from "../images/case/5/10.avif";
import case5_10mini from "../images/case/5/mini/10.jpeg";
import case5_10miniwebp from "../images/case/5/mini/10.webp";
import case5_10miniavif from "../images/case/5/mini/10.avif";
import case5_11 from "../images/case/5/11.jpeg";
import case5_11webp from "../images/case/5/11.webp";
import case5_11avif from "../images/case/5/11.avif";
import case5_11mini from "../images/case/5/mini/11.jpeg";
import case5_11miniwebp from "../images/case/5/mini/11.webp";
import case5_11miniavif from "../images/case/5/mini/11.avif";
import case5_12 from "../images/case/5/12.jpeg";
import case5_12webp from "../images/case/5/12.webp";
import case5_12avif from "../images/case/5/12.avif";
import case5_12mini from "../images/case/5/mini/12.jpeg";
import case5_12miniwebp from "../images/case/5/mini/12.webp";
import case5_12miniavif from "../images/case/5/mini/12.avif";
import case5_13 from "../images/case/5/13.jpeg";
import case5_13webp from "../images/case/5/13.webp";
import case5_13avif from "../images/case/5/13.avif";
import case5_13mini from "../images/case/5/mini/13.jpeg";
import case5_13miniwebp from "../images/case/5/mini/13.webp";
import case5_13miniavif from "../images/case/5/mini/13.avif";
import case5_14 from "../images/case/5/14.jpeg";
import case5_14webp from "../images/case/5/14.webp";
import case5_14avif from "../images/case/5/14.avif";
import case5_14mini from "../images/case/5/mini/14.jpeg";
import case5_14miniwebp from "../images/case/5/mini/14.webp";
import case5_14miniavif from "../images/case/5/mini/14.avif";
import case5_15 from "../images/case/5/15.jpeg";
import case5_15webp from "../images/case/5/15.webp";
import case5_15avif from "../images/case/5/15.avif";
import case5_15mini from "../images/case/5/mini/15.jpeg";
import case5_15miniwebp from "../images/case/5/mini/15.webp";
import case5_15miniavif from "../images/case/5/mini/15.avif";
import case5_16 from "../images/case/5/16.jpeg";
import case5_16webp from "../images/case/5/16.webp";
import case5_16avif from "../images/case/5/16.avif";
import case5_16mini from "../images/case/5/mini/16.jpeg";
import case5_16miniwebp from "../images/case/5/mini/16.webp";
import case5_16miniavif from "../images/case/5/mini/16.avif";
import case5_17 from "../images/case/5/17.jpeg";
import case5_17webp from "../images/case/5/17.webp";
import case5_17avif from "../images/case/5/17.avif";
import case5_17mini from "../images/case/5/mini/17.jpeg";
import case5_17miniwebp from "../images/case/5/mini/17.webp";
import case5_17miniavif from "../images/case/5/mini/17.avif";
import case5_18 from "../images/case/5/18.jpeg";
import case5_18webp from "../images/case/5/18.webp";
import case5_18avif from "../images/case/5/18.avif";
import case5_18mini from "../images/case/5/mini/18.jpeg";
import case5_18miniwebp from "../images/case/5/mini/18.webp";
import case5_18miniavif from "../images/case/5/mini/18.avif";
import case6_1main from "../images/case/6/1main.jpg";
import case6_1mainwebp from "../images/case/6/1main.webp";
import case6_1mainavif from "../images/case/6/1main.avif";
import case6_1 from "../images/case/6/1.jpeg";
import case6_1webp from "../images/case/6/1.webp";
import case6_1avif from "../images/case/6/1.avif";
import case6_1mini from "../images/case/6/mini/1.jpeg";
import case6_1miniwebp from "../images/case/6/mini/1.webp";
import case6_1miniavif from "../images/case/6/mini/1.avif";
import case6_2 from "../images/case/6/2.jpeg";
import case6_2webp from "../images/case/6/2.webp";
import case6_2avif from "../images/case/6/2.avif";
import case6_2mini from "../images/case/6/mini/2.jpeg";
import case6_2miniwebp from "../images/case/6/mini/2.webp";
import case6_2miniavif from "../images/case/6/mini/2.avif";
import case6_3 from "../images/case/6/3.jpeg";
import case6_3webp from "../images/case/6/3.webp";
import case6_3avif from "../images/case/6/3.avif";
import case6_3mini from "../images/case/6/mini/3.jpeg";
import case6_3miniwebp from "../images/case/6/mini/3.webp";
import case6_3miniavif from "../images/case/6/mini/3.avif";
import case6_4 from "../images/case/6/4.jpeg";
import case6_4webp from "../images/case/6/4.webp";
import case6_4avif from "../images/case/6/4.avif";
import case6_4mini from "../images/case/6/mini/4.jpeg";
import case6_4miniwebp from "../images/case/6/mini/4.webp";
import case6_4miniavif from "../images/case/6/mini/4.avif";
import case6_5 from "../images/case/6/5.jpeg";
import case6_5webp from "../images/case/6/5.webp";
import case6_5avif from "../images/case/6/5.avif";
import case6_5mini from "../images/case/6/mini/5.jpeg";
import case6_5miniwebp from "../images/case/6/mini/5.webp";
import case6_5miniavif from "../images/case/6/mini/5.avif";
import case6_6 from "../images/case/6/6.jpeg";
import case6_6webp from "../images/case/6/6.webp";
import case6_6avif from "../images/case/6/6.avif";
import case6_6mini from "../images/case/6/mini/6.jpeg";
import case6_6miniwebp from "../images/case/6/mini/6.webp";
import case6_6miniavif from "../images/case/6/mini/6.avif";
import case6_7 from "../images/case/6/7.jpeg";
import case6_7webp from "../images/case/6/7.webp";
import case6_7avif from "../images/case/6/7.avif";
import case6_7mini from "../images/case/6/mini/7.jpeg";
import case6_7miniwebp from "../images/case/6/mini/7.webp";
import case6_7miniavif from "../images/case/6/mini/7.avif";
import case6_8 from "../images/case/6/8.jpeg";
import case6_8webp from "../images/case/6/8.webp";
import case6_8avif from "../images/case/6/8.avif";
import case6_8mini from "../images/case/6/mini/8.jpeg";
import case6_8miniwebp from "../images/case/6/mini/8.webp";
import case6_8miniavif from "../images/case/6/mini/8.avif";
import case6_9 from "../images/case/6/9.jpeg";
import case6_9webp from "../images/case/6/9.webp";
import case6_9avif from "../images/case/6/9.avif";
import case6_9mini from "../images/case/6/mini/9.jpeg";
import case6_9miniwebp from "../images/case/6/mini/9.webp";
import case6_9miniavif from "../images/case/6/mini/9.avif";
import case6_10 from "../images/case/6/10.jpeg";
import case6_10webp from "../images/case/6/10.webp";
import case6_10avif from "../images/case/6/10.avif";
import case6_10mini from "../images/case/6/mini/10.jpeg";
import case6_10miniwebp from "../images/case/6/mini/10.webp";
import case6_10miniavif from "../images/case/6/mini/10.avif";
import case6_11 from "../images/case/6/11.jpeg";
import case6_11webp from "../images/case/6/11.webp";
import case6_11avif from "../images/case/6/11.avif";
import case6_11mini from "../images/case/6/mini/11.jpeg";
import case6_11miniwebp from "../images/case/6/mini/11.webp";
import case6_11miniavif from "../images/case/6/mini/11.avif";
import case6_12 from "../images/case/6/12.jpeg";
import case6_12webp from "../images/case/6/12.webp";
import case6_12avif from "../images/case/6/12.avif";
import case6_12mini from "../images/case/6/mini/12.jpeg";
import case6_12miniwebp from "../images/case/6/mini/12.webp";
import case6_12miniavif from "../images/case/6/mini/12.avif";
import case6_13 from "../images/case/6/13.jpeg";
import case6_13webp from "../images/case/6/13.webp";
import case6_13avif from "../images/case/6/13.avif";
import case6_13mini from "../images/case/6/mini/13.jpeg";
import case6_13miniwebp from "../images/case/6/mini/13.webp";
import case6_13miniavif from "../images/case/6/mini/13.avif";
import case6_14 from "../images/case/6/14.jpeg";
import case6_14webp from "../images/case/6/14.webp";
import case6_14avif from "../images/case/6/14.avif";
import case6_14mini from "../images/case/6/mini/14.jpeg";
import case6_14miniwebp from "../images/case/6/mini/14.webp";
import case6_14miniavif from "../images/case/6/mini/14.avif";
import case6_15 from "../images/case/6/15.jpeg";
import case6_15webp from "../images/case/6/15.webp";
import case6_15avif from "../images/case/6/15.avif";
import case6_15mini from "../images/case/6/mini/15.jpeg";
import case6_15miniwebp from "../images/case/6/mini/15.webp";
import case6_15miniavif from "../images/case/6/mini/15.avif";
import case6_16 from "../images/case/6/16.jpeg";
import case6_16webp from "../images/case/6/16.webp";
import case6_16avif from "../images/case/6/16.avif";
import case6_16mini from "../images/case/6/mini/16.jpeg";
import case6_16miniwebp from "../images/case/6/mini/16.webp";
import case6_16miniavif from "../images/case/6/mini/16.avif";
import case6_17 from "../images/case/6/17.jpeg";
import case6_17webp from "../images/case/6/17.webp";
import case6_17avif from "../images/case/6/17.avif";
import case6_17mini from "../images/case/6/mini/17.jpeg";
import case6_17miniwebp from "../images/case/6/mini/17.webp";
import case6_17miniavif from "../images/case/6/mini/17.avif";
import case6_18 from "../images/case/6/18.jpeg";
import case6_18webp from "../images/case/6/18.webp";
import case6_18avif from "../images/case/6/18.avif";
import case6_18mini from "../images/case/6/mini/18.jpeg";
import case6_18miniwebp from "../images/case/6/mini/18.webp";
import case6_18miniavif from "../images/case/6/mini/18.avif";
import case6_19 from "../images/case/6/19.jpeg";
import case6_19webp from "../images/case/6/19.webp";
import case6_19avif from "../images/case/6/19.avif";
import case6_19mini from "../images/case/6/mini/19.jpeg";
import case6_19miniwebp from "../images/case/6/mini/19.webp";
import case6_19miniavif from "../images/case/6/mini/19.avif";
import case6_20 from "../images/case/6/20.jpeg";
import case6_20webp from "../images/case/6/20.webp";
import case6_20avif from "../images/case/6/20.avif";
import case6_20mini from "../images/case/6/mini/20.jpeg";
import case6_20miniwebp from "../images/case/6/mini/20.webp";
import case6_20miniavif from "../images/case/6/mini/20.avif";
import case6_21 from "../images/case/6/21.jpeg";
import case6_21webp from "../images/case/6/21.webp";
import case6_21avif from "../images/case/6/21.avif";
import case6_21mini from "../images/case/6/mini/21.jpeg";
import case6_21miniwebp from "../images/case/6/mini/21.webp";
import case6_21miniavif from "../images/case/6/mini/21.avif";
import case6_22 from "../images/case/6/22.jpeg";
import case6_22webp from "../images/case/6/22.webp";
import case6_22avif from "../images/case/6/22.avif";
import case6_22mini from "../images/case/6/mini/22.jpeg";
import case6_22miniwebp from "../images/case/6/mini/22.webp";
import case6_22miniavif from "../images/case/6/mini/22.avif";
import case6_23 from "../images/case/6/23.jpeg";
import case6_23webp from "../images/case/6/23.webp";
import case6_23avif from "../images/case/6/23.avif";
import case6_23mini from "../images/case/6/mini/23.jpeg";
import case6_23miniwebp from "../images/case/6/mini/23.webp";
import case6_23miniavif from "../images/case/6/mini/23.avif";
import case6_24 from "../images/case/6/24.jpeg";
import case6_24webp from "../images/case/6/24.webp";
import case6_24avif from "../images/case/6/24.avif";
import case6_24mini from "../images/case/6/mini/24.jpeg";
import case6_24miniwebp from "../images/case/6/mini/24.webp";
import case6_24miniavif from "../images/case/6/mini/24.avif";
import case6_25 from "../images/case/6/25.jpeg";
import case6_25webp from "../images/case/6/25.webp";
import case6_25avif from "../images/case/6/25.avif";
import case6_25mini from "../images/case/6/mini/25.jpeg";
import case6_25miniwebp from "../images/case/6/mini/25.webp";
import case6_25miniavif from "../images/case/6/mini/25.avif";
import case6_26 from "../images/case/6/26.jpeg";
import case6_26webp from "../images/case/6/26.webp";
import case6_26avif from "../images/case/6/26.avif";
import case6_26mini from "../images/case/6/mini/26.jpeg";
import case6_26miniwebp from "../images/case/6/mini/26.webp";
import case6_26miniavif from "../images/case/6/mini/26.avif";
import case6_27 from "../images/case/6/27.jpeg";
import case6_27webp from "../images/case/6/27.webp";
import case6_27avif from "../images/case/6/27.avif";
import case6_27mini from "../images/case/6/mini/27.jpeg";
import case6_27miniwebp from "../images/case/6/mini/27.webp";
import case6_27miniavif from "../images/case/6/mini/27.avif";
import case6_28 from "../images/case/6/28.jpeg";
import case6_28webp from "../images/case/6/28.webp";
import case6_28avif from "../images/case/6/28.avif";
import case6_28mini from "../images/case/6/mini/28.jpeg";
import case6_28miniwebp from "../images/case/6/mini/28.webp";
import case6_28miniavif from "../images/case/6/mini/28.avif";
import case6_29 from "../images/case/6/29.jpeg";
import case6_29webp from "../images/case/6/29.webp";
import case6_29avif from "../images/case/6/29.avif";
import case6_29mini from "../images/case/6/mini/29.jpeg";
import case6_29miniwebp from "../images/case/6/mini/29.webp";
import case6_29miniavif from "../images/case/6/mini/29.avif";
import case6_30 from "../images/case/6/30.jpeg";
import case6_30webp from "../images/case/6/30.webp";
import case6_30avif from "../images/case/6/30.avif";
import case6_30mini from "../images/case/6/mini/30.jpeg";
import case6_30miniwebp from "../images/case/6/mini/30.webp";
import case6_30miniavif from "../images/case/6/mini/30.avif";
import case6_31 from "../images/case/6/31.jpeg";
import case6_31webp from "../images/case/6/31.webp";
import case6_31avif from "../images/case/6/31.avif";
import case6_31mini from "../images/case/6/mini/31.jpeg";
import case6_31miniwebp from "../images/case/6/mini/31.webp";
import case6_31miniavif from "../images/case/6/mini/31.avif";
import case6_32 from "../images/case/6/32.jpeg";
import case6_32webp from "../images/case/6/32.webp";
import case6_32avif from "../images/case/6/32.avif";
import case6_32mini from "../images/case/6/mini/32.jpeg";
import case6_32miniwebp from "../images/case/6/mini/32.webp";
import case6_32miniavif from "../images/case/6/mini/32.avif";
import case6_33 from "../images/case/6/33.jpeg";
import case6_33webp from "../images/case/6/33.webp";
import case6_33avif from "../images/case/6/33.avif";
import case6_33mini from "../images/case/6/mini/33.jpeg";
import case6_33miniwebp from "../images/case/6/mini/33.webp";
import case6_33miniavif from "../images/case/6/mini/33.avif";
import case6_34 from "../images/case/6/34.jpeg";
import case6_34webp from "../images/case/6/34.webp";
import case6_34avif from "../images/case/6/34.avif";
import case6_34mini from "../images/case/6/mini/34.jpeg";
import case6_34miniwebp from "../images/case/6/mini/34.webp";
import case6_34miniavif from "../images/case/6/mini/34.avif";
import case6_35 from "../images/case/6/35.jpeg";
import case6_35webp from "../images/case/6/35.webp";
import case6_35avif from "../images/case/6/35.avif";
import case6_35mini from "../images/case/6/mini/35.jpeg";
import case6_35miniwebp from "../images/case/6/mini/35.webp";
import case6_35miniavif from "../images/case/6/mini/35.avif";
import case6_36 from "../images/case/6/36.jpeg";
import case6_36webp from "../images/case/6/36.webp";
import case6_36avif from "../images/case/6/36.avif";
import case6_36mini from "../images/case/6/mini/36.jpeg";
import case6_36miniwebp from "../images/case/6/mini/36.webp";
import case6_36miniavif from "../images/case/6/mini/36.avif";
import case6_37 from "../images/case/6/37.jpeg";
import case6_37webp from "../images/case/6/37.webp";
import case6_37avif from "../images/case/6/37.avif";
import case6_37mini from "../images/case/6/mini/37.jpeg";
import case6_37miniwebp from "../images/case/6/mini/37.webp";
import case6_37miniavif from "../images/case/6/mini/37.avif";
import case6_38 from "../images/case/6/38.jpeg";
import case6_38webp from "../images/case/6/38.webp";
import case6_38avif from "../images/case/6/38.avif";
import case6_38mini from "../images/case/6/mini/38.jpeg";
import case6_38miniwebp from "../images/case/6/mini/38.webp";
import case6_38miniavif from "../images/case/6/mini/38.avif";
import case6_39 from "../images/case/6/39.jpeg";
import case6_39webp from "../images/case/6/39.webp";
import case6_39avif from "../images/case/6/39.avif";
import case6_39mini from "../images/case/6/mini/39.jpeg";
import case6_39miniwebp from "../images/case/6/mini/39.webp";
import case6_39miniavif from "../images/case/6/mini/39.avif";
import case6_40 from "../images/case/6/40.jpeg";
import case6_40webp from "../images/case/6/40.webp";
import case6_40avif from "../images/case/6/40.avif";
import case6_40mini from "../images/case/6/mini/40.jpeg";
import case6_40miniwebp from "../images/case/6/mini/40.webp";
import case6_40miniavif from "../images/case/6/mini/40.avif";
import case6_41 from "../images/case/6/41.jpeg";
import case6_41webp from "../images/case/6/41.webp";
import case6_41avif from "../images/case/6/41.avif";
import case6_41mini from "../images/case/6/mini/41.jpeg";
import case6_41miniwebp from "../images/case/6/mini/41.webp";
import case6_41miniavif from "../images/case/6/mini/41.avif";
import case6_42 from "../images/case/6/42.jpeg";
import case6_42webp from "../images/case/6/42.webp";
import case6_42avif from "../images/case/6/42.avif";
import case6_42mini from "../images/case/6/mini/42.jpeg";
import case6_42miniwebp from "../images/case/6/mini/42.webp";
import case6_42miniavif from "../images/case/6/mini/42.avif";
import case6_43 from "../images/case/6/43.jpeg";
import case6_43webp from "../images/case/6/43.webp";
import case6_43avif from "../images/case/6/43.avif";
import case6_43mini from "../images/case/6/mini/43.jpeg";
import case6_43miniwebp from "../images/case/6/mini/43.webp";
import case6_43miniavif from "../images/case/6/mini/43.avif";
import case6_44 from "../images/case/6/44.jpeg";
import case6_44webp from "../images/case/6/44.webp";
import case6_44avif from "../images/case/6/44.avif";
import case6_44mini from "../images/case/6/mini/44.jpeg";
import case6_44miniwebp from "../images/case/6/mini/44.webp";
import case6_44miniavif from "../images/case/6/mini/44.avif";
import case7_1main from "../images/case/7/1main.jpg";
import case7_1mainwebp from "../images/case/7/1main.webp";
import case7_1mainavif from "../images/case/7/1main.avif";
import case7_1 from "../images/case/7/1.jpeg";
import case7_1webp from "../images/case/7/1.webp";
import case7_1avif from "../images/case/7/1.avif";
import case7_1mini from "../images/case/7/mini/1.jpeg";
import case7_1miniwebp from "../images/case/7/mini/1.webp";
import case7_1miniavif from "../images/case/7/mini/1.avif";
import case7_2 from "../images/case/7/2.jpeg";
import case7_2webp from "../images/case/7/2.webp";
import case7_2avif from "../images/case/7/2.avif";
import case7_2mini from "../images/case/7/mini/2.jpeg";
import case7_2miniwebp from "../images/case/7/mini/2.webp";
import case7_2miniavif from "../images/case/7/mini/2.avif";
import case7_3 from "../images/case/7/3.jpeg";
import case7_3webp from "../images/case/7/3.webp";
import case7_3avif from "../images/case/7/3.avif";
import case7_3mini from "../images/case/7/mini/3.jpeg";
import case7_3miniwebp from "../images/case/7/mini/3.webp";
import case7_3miniavif from "../images/case/7/mini/3.avif";
import case7_4 from "../images/case/7/4.jpeg";
import case7_4webp from "../images/case/7/4.webp";
import case7_4avif from "../images/case/7/4.avif";
import case7_4mini from "../images/case/7/mini/4.jpeg";
import case7_4miniwebp from "../images/case/7/mini/4.webp";
import case7_4miniavif from "../images/case/7/mini/4.avif";
import case7_5 from "../images/case/7/5.jpeg";
import case7_5webp from "../images/case/7/5.webp";
import case7_5avif from "../images/case/7/5.avif";
import case7_5mini from "../images/case/7/mini/5.jpeg";
import case7_5miniwebp from "../images/case/7/mini/5.webp";
import case7_5miniavif from "../images/case/7/mini/5.avif";
import case7_6 from "../images/case/7/6.jpeg";
import case7_6webp from "../images/case/7/6.webp";
import case7_6avif from "../images/case/7/6.avif";
import case7_6mini from "../images/case/7/mini/6.jpeg";
import case7_6miniwebp from "../images/case/7/mini/6.webp";
import case7_6miniavif from "../images/case/7/mini/6.avif";
import case7_7 from "../images/case/7/7.jpeg";
import case7_7webp from "../images/case/7/7.webp";
import case7_7avif from "../images/case/7/7.avif";
import case7_7mini from "../images/case/7/mini/7.jpeg";
import case7_7miniwebp from "../images/case/7/mini/7.webp";
import case7_7miniavif from "../images/case/7/mini/7.avif";
import case7_8 from "../images/case/7/8.jpeg";
import case7_8webp from "../images/case/7/8.webp";
import case7_8avif from "../images/case/7/8.avif";
import case7_8mini from "../images/case/7/mini/8.jpeg";
import case7_8miniwebp from "../images/case/7/mini/8.webp";
import case7_8miniavif from "../images/case/7/mini/8.avif";
import case7_9 from "../images/case/7/9.jpeg";
import case7_9webp from "../images/case/7/9.webp";
import case7_9avif from "../images/case/7/9.avif";
import case7_9mini from "../images/case/7/mini/9.jpeg";
import case7_9miniwebp from "../images/case/7/mini/9.webp";
import case7_9miniavif from "../images/case/7/mini/9.avif";
import case7_10 from "../images/case/7/10.jpeg";
import case7_10webp from "../images/case/7/10.webp";
import case7_10avif from "../images/case/7/10.avif";
import case7_10mini from "../images/case/7/mini/10.jpeg";
import case7_10miniwebp from "../images/case/7/mini/10.webp";
import case7_10miniavif from "../images/case/7/mini/10.avif";
import case7_11 from "../images/case/7/11.jpeg";
import case7_11webp from "../images/case/7/11.webp";
import case7_11avif from "../images/case/7/11.avif";
import case7_11mini from "../images/case/7/mini/11.jpeg";
import case7_11miniwebp from "../images/case/7/mini/11.webp";
import case7_11miniavif from "../images/case/7/mini/11.avif";
import case7_12 from "../images/case/7/12.jpeg";
import case7_12webp from "../images/case/7/12.webp";
import case7_12avif from "../images/case/7/12.avif";
import case7_12mini from "../images/case/7/mini/12.jpeg";
import case7_12miniwebp from "../images/case/7/mini/12.webp";
import case7_12miniavif from "../images/case/7/mini/12.avif";
import case7_13 from "../images/case/7/13.jpeg";
import case7_13webp from "../images/case/7/13.webp";
import case7_13avif from "../images/case/7/13.avif";
import case7_13mini from "../images/case/7/mini/13.jpeg";
import case7_13miniwebp from "../images/case/7/mini/13.webp";
import case7_13miniavif from "../images/case/7/mini/13.avif";
import case7_14 from "../images/case/7/14.jpeg";
import case7_14webp from "../images/case/7/14.webp";
import case7_14avif from "../images/case/7/14.avif";
import case7_14mini from "../images/case/7/mini/14.jpeg";
import case7_14miniwebp from "../images/case/7/mini/14.webp";
import case7_14miniavif from "../images/case/7/mini/14.avif";
import case7_15 from "../images/case/7/15.jpeg";
import case7_15webp from "../images/case/7/15.webp";
import case7_15avif from "../images/case/7/15.avif";
import case7_15mini from "../images/case/7/mini/15.jpeg";
import case7_15miniwebp from "../images/case/7/mini/15.webp";
import case7_15miniavif from "../images/case/7/mini/15.avif";
import case7_16 from "../images/case/7/16.jpeg";
import case7_16webp from "../images/case/7/16.webp";
import case7_16avif from "../images/case/7/16.avif";
import case7_16mini from "../images/case/7/mini/16.jpeg";
import case7_16miniwebp from "../images/case/7/mini/16.webp";
import case7_16miniavif from "../images/case/7/mini/16.avif";
import case7_17 from "../images/case/7/17.jpeg";
import case7_17webp from "../images/case/7/17.webp";
import case7_17avif from "../images/case/7/17.avif";
import case7_17mini from "../images/case/7/mini/17.jpeg";
import case7_17miniwebp from "../images/case/7/mini/17.webp";
import case7_17miniavif from "../images/case/7/mini/17.avif";
import case7_18 from "../images/case/7/18.jpeg";
import case7_18webp from "../images/case/7/18.webp";
import case7_18avif from "../images/case/7/18.avif";
import case7_18mini from "../images/case/7/mini/18.jpeg";
import case7_18miniwebp from "../images/case/7/mini/18.webp";
import case7_18miniavif from "../images/case/7/mini/18.avif";
import case7_19 from "../images/case/7/19.jpeg";
import case7_19webp from "../images/case/7/19.webp";
import case7_19avif from "../images/case/7/19.avif";
import case7_19mini from "../images/case/7/mini/19.jpeg";
import case7_19miniwebp from "../images/case/7/mini/19.webp";
import case7_19miniavif from "../images/case/7/mini/19.avif";
import case7_20 from "../images/case/7/20.jpeg";
import case7_20webp from "../images/case/7/20.webp";
import case7_20avif from "../images/case/7/20.avif";
import case7_20mini from "../images/case/7/mini/20.jpeg";
import case7_20miniwebp from "../images/case/7/mini/20.webp";
import case7_20miniavif from "../images/case/7/mini/20.avif";
import case8_1main from "../images/case/8/1main.jpg";
import case8_1mainwebp from "../images/case/8/1main.webp";
import case8_1mainavif from "../images/case/8/1main.avif";
import case8_1 from "../images/case/8/1.jpeg";
import case8_1webp from "../images/case/8/1.webp";
import case8_1avif from "../images/case/8/1.avif";
import case8_1mini from "../images/case/8/mini/1.jpeg";
import case8_1miniwebp from "../images/case/8/mini/1.webp";
import case8_1miniavif from "../images/case/8/mini/1.avif";
import case8_2 from "../images/case/8/2.jpeg";
import case8_2webp from "../images/case/8/2.webp";
import case8_2avif from "../images/case/8/2.avif";
import case8_2mini from "../images/case/8/mini/2.jpeg";
import case8_2miniwebp from "../images/case/8/mini/2.webp";
import case8_2miniavif from "../images/case/8/mini/2.avif";
import case8_3 from "../images/case/8/3.jpeg";
import case8_3webp from "../images/case/8/3.webp";
import case8_3avif from "../images/case/8/3.avif";
import case8_3mini from "../images/case/8/mini/3.jpeg";
import case8_3miniwebp from "../images/case/8/mini/3.webp";
import case8_3miniavif from "../images/case/8/mini/3.avif";
import case8_4 from "../images/case/8/4.jpeg";
import case8_4webp from "../images/case/8/4.webp";
import case8_4avif from "../images/case/8/4.avif";
import case8_4mini from "../images/case/8/mini/4.jpeg";
import case8_4miniwebp from "../images/case/8/mini/4.webp";
import case8_4miniavif from "../images/case/8/mini/4.avif";
import case8_5 from "../images/case/8/5.jpeg";
import case8_5webp from "../images/case/8/5.webp";
import case8_5avif from "../images/case/8/5.avif";
import case8_5mini from "../images/case/8/mini/5.jpeg";
import case8_5miniwebp from "../images/case/8/mini/5.webp";
import case8_5miniavif from "../images/case/8/mini/5.avif";
import case8_6 from "../images/case/8/6.jpeg";
import case8_6webp from "../images/case/8/6.webp";
import case8_6avif from "../images/case/8/6.avif";
import case8_6mini from "../images/case/8/mini/6.jpeg";
import case8_6miniwebp from "../images/case/8/mini/6.webp";
import case8_6miniavif from "../images/case/8/mini/6.avif";
import case8_7 from "../images/case/8/7.jpeg";
import case8_7webp from "../images/case/8/7.webp";
import case8_7avif from "../images/case/8/7.avif";
import case8_7mini from "../images/case/8/mini/7.jpeg";
import case8_7miniwebp from "../images/case/8/mini/7.webp";
import case8_7miniavif from "../images/case/8/mini/7.avif";
import case8_8 from "../images/case/8/8.jpeg";
import case8_8webp from "../images/case/8/8.webp";
import case8_8avif from "../images/case/8/8.avif";
import case8_8mini from "../images/case/8/mini/8.jpeg";
import case8_8miniwebp from "../images/case/8/mini/8.webp";
import case8_8miniavif from "../images/case/8/mini/8.avif";
import case8_9 from "../images/case/8/9.jpeg";
import case8_9webp from "../images/case/8/9.webp";
import case8_9avif from "../images/case/8/9.avif";
import case8_9mini from "../images/case/8/mini/9.jpeg";
import case8_9miniwebp from "../images/case/8/mini/9.webp";
import case8_9miniavif from "../images/case/8/mini/9.avif";
import case8_10 from "../images/case/8/10.jpeg";
import case8_10webp from "../images/case/8/10.webp";
import case8_10avif from "../images/case/8/10.avif";
import case8_10mini from "../images/case/8/mini/10.jpeg";
import case8_10miniwebp from "../images/case/8/mini/10.webp";
import case8_10miniavif from "../images/case/8/mini/10.avif";
import case8_11 from "../images/case/8/11.jpeg";
import case8_11webp from "../images/case/8/11.webp";
import case8_11avif from "../images/case/8/11.avif";
import case8_11mini from "../images/case/8/mini/11.jpeg";
import case8_11miniwebp from "../images/case/8/mini/11.webp";
import case8_11miniavif from "../images/case/8/mini/11.avif";
import case8_12 from "../images/case/8/12.jpeg";
import case8_12webp from "../images/case/8/12.webp";
import case8_12avif from "../images/case/8/12.avif";
import case8_12mini from "../images/case/8/mini/12.jpeg";
import case8_12miniwebp from "../images/case/8/mini/12.webp";
import case8_12miniavif from "../images/case/8/mini/12.avif";
import case8_13 from "../images/case/8/13.jpeg";
import case8_13webp from "../images/case/8/13.webp";
import case8_13avif from "../images/case/8/13.avif";
import case8_13mini from "../images/case/8/mini/13.jpeg";
import case8_13miniwebp from "../images/case/8/mini/13.webp";
import case8_13miniavif from "../images/case/8/mini/13.avif";
import case8_14 from "../images/case/8/14.jpeg";
import case8_14webp from "../images/case/8/14.webp";
import case8_14avif from "../images/case/8/14.avif";
import case8_14mini from "../images/case/8/mini/14.jpeg";
import case8_14miniwebp from "../images/case/8/mini/14.webp";
import case8_14miniavif from "../images/case/8/mini/14.avif";
import case8_15 from "../images/case/8/15.jpeg";
import case8_15webp from "../images/case/8/15.webp";
import case8_15avif from "../images/case/8/15.avif";
import case8_15mini from "../images/case/8/mini/15.jpeg";
import case8_15miniwebp from "../images/case/8/mini/15.webp";
import case8_15miniavif from "../images/case/8/mini/15.avif";
import case8_16 from "../images/case/8/16.jpeg";
import case8_16webp from "../images/case/8/16.webp";
import case8_16avif from "../images/case/8/16.avif";
import case8_16mini from "../images/case/8/mini/16.jpeg";
import case8_16miniwebp from "../images/case/8/mini/16.webp";
import case8_16miniavif from "../images/case/8/mini/16.avif";
import case8_17 from "../images/case/8/17.jpeg";
import case8_17webp from "../images/case/8/17.webp";
import case8_17avif from "../images/case/8/17.avif";
import case8_17mini from "../images/case/8/mini/17.jpeg";
import case8_17miniwebp from "../images/case/8/mini/17.webp";
import case8_17miniavif from "../images/case/8/mini/17.avif";
import case8_18 from "../images/case/8/18.jpeg";
import case8_18webp from "../images/case/8/18.webp";
import case8_18avif from "../images/case/8/18.avif";
import case8_18mini from "../images/case/8/mini/18.jpeg";
import case8_18miniwebp from "../images/case/8/mini/18.webp";
import case8_18miniavif from "../images/case/8/mini/18.avif";
import case8_19 from "../images/case/8/19.jpeg";
import case8_19webp from "../images/case/8/19.webp";
import case8_19avif from "../images/case/8/19.avif";
import case8_19mini from "../images/case/8/mini/19.jpeg";
import case8_19miniwebp from "../images/case/8/mini/19.webp";
import case8_19miniavif from "../images/case/8/mini/19.avif";
import case8_20 from "../images/case/8/20.jpeg";
import case8_20webp from "../images/case/8/20.webp";
import case8_20avif from "../images/case/8/20.avif";
import case8_20mini from "../images/case/8/mini/20.jpeg";
import case8_20miniwebp from "../images/case/8/mini/20.webp";
import case8_20miniavif from "../images/case/8/mini/20.avif";
import case8_21 from "../images/case/8/21.jpeg";
import case8_21webp from "../images/case/8/21.webp";
import case8_21avif from "../images/case/8/21.avif";
import case8_21mini from "../images/case/8/mini/21.jpeg";
import case8_21miniwebp from "../images/case/8/mini/21.webp";
import case8_21miniavif from "../images/case/8/mini/21.avif";
import case8_22 from "../images/case/8/22.jpeg";
import case8_22webp from "../images/case/8/22.webp";
import case8_22avif from "../images/case/8/22.avif";
import case8_22mini from "../images/case/8/mini/22.jpeg";
import case8_22miniwebp from "../images/case/8/mini/22.webp";
import case8_22miniavif from "../images/case/8/mini/22.avif";
import case8_23 from "../images/case/8/23.jpeg";
import case8_23webp from "../images/case/8/23.webp";
import case8_23avif from "../images/case/8/23.avif";
import case8_23mini from "../images/case/8/mini/23.jpeg";
import case8_23miniwebp from "../images/case/8/mini/23.webp";
import case8_23miniavif from "../images/case/8/mini/23.avif";
import case8_24 from "../images/case/8/24.jpeg";
import case8_24webp from "../images/case/8/24.webp";
import case8_24avif from "../images/case/8/24.avif";
import case8_24mini from "../images/case/8/mini/24.jpeg";
import case8_24miniwebp from "../images/case/8/mini/24.webp";
import case8_24miniavif from "../images/case/8/mini/24.avif";
import case8_25 from "../images/case/8/25.jpeg";
import case8_25webp from "../images/case/8/25.webp";
import case8_25avif from "../images/case/8/25.avif";
import case8_25mini from "../images/case/8/mini/25.jpeg";
import case8_25miniwebp from "../images/case/8/mini/25.webp";
import case8_25miniavif from "../images/case/8/mini/25.avif";
import case8_26 from "../images/case/8/26.jpeg";
import case8_26webp from "../images/case/8/26.webp";
import case8_26avif from "../images/case/8/26.avif";
import case8_26mini from "../images/case/8/mini/26.jpeg";
import case8_26miniwebp from "../images/case/8/mini/26.webp";
import case8_26miniavif from "../images/case/8/mini/26.avif";
import case8_27 from "../images/case/8/27.jpeg";
import case8_27webp from "../images/case/8/27.webp";
import case8_27avif from "../images/case/8/27.avif";
import case8_27mini from "../images/case/8/mini/27.jpeg";
import case8_27miniwebp from "../images/case/8/mini/27.webp";
import case8_27miniavif from "../images/case/8/mini/27.avif";
import case8_28 from "../images/case/8/28.jpeg";
import case8_28webp from "../images/case/8/28.webp";
import case8_28avif from "../images/case/8/28.avif";
import case8_28mini from "../images/case/8/mini/28.jpeg";
import case8_28miniwebp from "../images/case/8/mini/28.webp";
import case8_28miniavif from "../images/case/8/mini/28.avif";
import case8_29 from "../images/case/8/29.jpeg";
import case8_29webp from "../images/case/8/29.webp";
import case8_29avif from "../images/case/8/29.avif";
import case8_29mini from "../images/case/8/mini/29.jpeg";
import case8_29miniwebp from "../images/case/8/mini/29.webp";
import case8_29miniavif from "../images/case/8/mini/29.avif";
import case8_30 from "../images/case/8/30.jpeg";
import case8_30webp from "../images/case/8/30.webp";
import case8_30avif from "../images/case/8/30.avif";
import case8_30mini from "../images/case/8/mini/30.jpeg";
import case8_30miniwebp from "../images/case/8/mini/30.webp";
import case8_30miniavif from "../images/case/8/mini/30.avif";
import case9_1main from "../images/case/9/1main.jpg";
import case9_1mainwebp from "../images/case/9/1main.webp";
import case9_1mainavif from "../images/case/9/1main.avif";
import case9_1 from "../images/case/9/1.jpeg";
import case9_1webp from "../images/case/9/1.webp";
import case9_1avif from "../images/case/9/1.avif";
import case9_1mini from "../images/case/9/mini/1.jpeg";
import case9_1miniwebp from "../images/case/9/mini/1.webp";
import case9_1miniavif from "../images/case/9/mini/1.avif";
import case9_2 from "../images/case/9/2.jpeg";
import case9_2webp from "../images/case/9/2.webp";
import case9_2avif from "../images/case/9/2.avif";
import case9_2mini from "../images/case/9/mini/2.jpeg";
import case9_2miniwebp from "../images/case/9/mini/2.webp";
import case9_2miniavif from "../images/case/9/mini/2.avif";
import case9_3 from "../images/case/9/3.jpeg";
import case9_3webp from "../images/case/9/3.webp";
import case9_3avif from "../images/case/9/3.avif";
import case9_3mini from "../images/case/9/mini/3.jpeg";
import case9_3miniwebp from "../images/case/9/mini/3.webp";
import case9_3miniavif from "../images/case/9/mini/3.avif";
import case9_4 from "../images/case/9/4.jpeg";
import case9_4webp from "../images/case/9/4.webp";
import case9_4avif from "../images/case/9/4.avif";
import case9_4mini from "../images/case/9/mini/4.jpeg";
import case9_4miniwebp from "../images/case/9/mini/4.webp";
import case9_4miniavif from "../images/case/9/mini/4.avif";
import case9_5 from "../images/case/9/5.jpeg";
import case9_5webp from "../images/case/9/5.webp";
import case9_5avif from "../images/case/9/5.avif";
import case9_5mini from "../images/case/9/mini/5.jpeg";
import case9_5miniwebp from "../images/case/9/mini/5.webp";
import case9_5miniavif from "../images/case/9/mini/5.avif";
import case9_6 from "../images/case/9/6.jpeg";
import case9_6webp from "../images/case/9/6.webp";
import case9_6avif from "../images/case/9/6.avif";
import case9_6mini from "../images/case/9/mini/6.jpeg";
import case9_6miniwebp from "../images/case/9/mini/6.webp";
import case9_6miniavif from "../images/case/9/mini/6.avif";
import case9_7 from "../images/case/9/7.jpeg";
import case9_7webp from "../images/case/9/7.webp";
import case9_7avif from "../images/case/9/7.avif";
import case9_7mini from "../images/case/9/mini/7.jpeg";
import case9_7miniwebp from "../images/case/1/mini/7.webp";
import case9_7miniavif from "../images/case/1/mini/7.avif";
import case9_8 from "../images/case/9/8.jpeg";
import case9_8webp from "../images/case/1/8.webp";
import case9_8avif from "../images/case/1/8.avif";
import case9_8mini from "../images/case/9/mini/8.jpeg";
import case9_8miniwebp from "../images/case/9/mini/8.webp";
import case9_8miniavif from "../images/case/9/mini/8.avif";
import case9_9 from "../images/case/9/9.jpeg";
import case9_9webp from "../images/case/9/9.webp";
import case9_9avif from "../images/case/9/9.avif";
import case9_9mini from "../images/case/9/mini/9.jpeg";
import case9_9miniwebp from "../images/case/9/mini/9.webp";
import case9_9miniavif from "../images/case/9/mini/9.avif";
import case9_10 from "../images/case/9/10.jpeg";
import case9_10webp from "../images/case/9/10.webp";
import case9_10avif from "../images/case/9/10.avif";
import case9_10mini from "../images/case/9/mini/10.jpeg";
import case9_10miniwebp from "../images/case/9/mini/10.webp";
import case9_10miniavif from "../images/case/9/mini/10.avif";
import case9_11 from "../images/case/9/11.jpeg";
import case9_11webp from "../images/case/9/11.webp";
import case9_11avif from "../images/case/9/11.avif";
import case9_11mini from "../images/case/9/mini/11.jpeg";
import case9_11miniwebp from "../images/case/9/mini/11.webp";
import case9_11miniavif from "../images/case/9/mini/11.avif";
import case9_12 from "../images/case/9/12.jpeg";
import case9_12webp from "../images/case/9/12.webp";
import case9_12avif from "../images/case/9/12.avif";
import case9_12mini from "../images/case/9/mini/12.jpeg";
import case9_12miniwebp from "../images/case/9/mini/12.webp";
import case9_12miniavif from "../images/case/9/mini/12.avif";

export const cases = [
  {
    key: 1,
    srcMain: `${case1_1}`,
    srcMainWebp: `${case1_1webp}`,
    srcMainAvif: `${case1_1avif}`,
    src: [
      {
        number: 1,
        key: "1a",
        mini: `${case1_1mini}`,
        big: `${case1_1}`,
        miniwebp: `${case1_1miniwebp}`,
        miniavif: `${case1_1miniavif}`,
        bigwebp: `${case1_1webp}`,
        bigavif: `${case1_1avif}`,
      },
      {
        number: 2,
        key: "2a",
        mini: `${case1_2mini}`,
        big: `${case1_2}`,
        miniwebp: `${case1_2miniwebp}`,
        miniavif: `${case1_2miniavif}`,
        bigwebp: `${case1_2webp}`,
        bigavif: `${case1_2avif}`,
      },
      {
        number: 3,
        key: "3a",
        mini: `${case1_3mini}`,
        big: `${case1_3}`,
        miniwebp: `${case1_3miniwebp}`,
        miniavif: `${case1_3miniavif}`,
        bigwebp: `${case1_3webp}`,
        bigavif: `${case1_3avif}`,
      },
      {
        number: 4,
        key: "4a",
        mini: `${case1_4mini}`,
        big: `${case1_4}`,
        miniwebp: `${case1_4miniwebp}`,
        miniavif: `${case1_4miniavif}`,
        bigwebp: `${case1_4webp}`,
        bigavif: `${case1_4avif}`,
      },
      {
        number: 5,
        key: "5a",
        mini: `${case1_5mini}`,
        big: `${case1_5}`,
        miniwebp: `${case1_5miniwebp}`,
        miniavif: `${case1_5miniavif}`,
        bigwebp: `${case1_5webp}`,
        bigavif: `${case1_5avif}`,
      },
      {
        number: 6,
        key: "6a",
        mini: `${case1_61mini}`,
        big: `${case1_6}`,
        miniwebp: `${case1_6miniwebp}`,
        miniavif: `${case1_6miniavif}`,
        bigwebp: `${case1_6webp}`,
        bigavif: `${case1_6avif}`,
      },
      {
        number: 7,
        key: "7a",
        mini: `${case1_7mini}`,
        big: `${case1_7}`,
        miniwebp: `${case1_7miniwebp}`,
        miniavif: `${case1_7miniavif}`,
        bigwebp: `${case1_7webp}`,
        bigavif: `${case1_7avif}`,
      },
      {
        number: 8,
        key: "8a",
        mini: `${case1_8mini}`,
        big: `${case1_8}`,
        miniwebp: `${case1_8miniwebp}`,
        miniavif: `${case1_8miniavif}`,
        bigwebp: `${case1_8webp}`,
        bigavif: `${case1_8avif}`,
      },
      {
        number: 9,
        key: "9a",
        mini: `${case1_9mini}`,
        big: `${case1_9}`,
        miniwebp: `${case1_9miniwebp}`,
        miniavif: `${case1_9miniavif}`,
        bigwebp: `${case1_9webp}`,
        bigavif: `${case1_9avif}`,
      },
      {
        number: 10,
        key: "10a",
        mini: `${case1_10mini}`,
        big: `${case1_10}`,
        miniwebp: `${case1_10miniwebp}`,
        miniavif: `${case1_10miniavif}`,
        bigwebp: `${case1_10webp}`,
        bigavif: `${case1_10avif}`,
      },
      {
        number: 11,
        key: "11a",
        mini: `${case1_11mini}`,
        big: `${case1_11}`,
        miniwebp: `${case1_11miniwebp}`,
        miniavif: `${case1_11miniavif}`,
        bigwebp: `${case1_11webp}`,
        bigavif: `${case1_11avif}`,
      },
      {
        number: 12,
        key: "12a",
        mini: `${case1_12mini}`,
        big: `${case1_12}`,
        miniwebp: `${case1_12miniwebp}`,
        miniavif: `${case1_12miniavif}`,
        bigwebp: `${case1_12webp}`,
        bigavif: `${case1_12avif}`,
      },
      {
        number: 13,
        key: "13a",
        mini: `${case1_13mini}`,
        big: `${case1_13}`,
        miniwebp: `${case1_13miniwebp}`,
        miniavif: `${case1_13miniavif}`,
        bigwebp: `${case1_13webp}`,
        bigavif: `${case1_13avif}`,
      },
      {
        number: 14,
        key: "14a",
        mini: `${case1_14mini}`,
        big: `${case1_14}`,
        miniwebp: `${case1_14miniwebp}`,
        miniavif: `${case1_14miniavif}`,
        bigwebp: `${case1_14webp}`,
        bigavif: `${case1_14avif}`,
      },
    ],
    title: "Каркасный дом, 118 м2, КП Стеклянный",
    text: [
      {
        key: "1a",
        title: "Отопление:",
        subtitle: [
          "1 этаж:",
          "65 м2 теплого пола с матами. 6 контуров. Утепление под трубой 53 мм. Общая толщина пирога со стяжкой 120 мм.",
          "Выполнен монтаж полусухой стяжки пола.",
          "2 этаж:",
          "3 радиатора Buderus 500 мм., с нижним подключением. Разводка через коллектор.",
        ],
      },
      {
        key: "2a",
        title: "Водоснабжение и канализация:",
        subtitle: [
          "Разводка водоснабжения и канализации на санузлы 1 и 2 этажей, и кухню. Разводка выполнена через коллектор. Все трубы смонтированы в тело стены, в дальнейшем скроются плиткой. Холодная и горячая вода у каждого прибора отключается отдельно.",
          "Смонтирован вводной узел ХВС. С установкой магистральных фильтров.",
        ],
      },
      {
        key: "3a",
        title: "Котельная: ",
        subtitle: [
          "Смонтирован электрический котел Protherm Skat 12 КВт",
          "Позднее был установлен GSM модуль, для управления системой отопления с помощью смартфона",
          "Смонтирован бойлер косвенного нагрева Stout 200 л., на специальную подставку. Бойлер подключен к котлу через трехходовой клапан Fugas. Время нагрева бойлера до 50-60 гр. 40 мин",
          "На теплый пол смонтирован узел подмеса Tim JH 1036 с насосом",
          "Гидравлическая обвязка котельной выполнена полипропиленом",
        ],
      },
    ],
    type: "каркас",
  },
  {
    key: 2,
    srcMain: `${case2_1}`,
    srcMainWebp: `${case2_1webp}`,
    srcMainAvif: `${case2_1avif}`,
    src: [
      {
        number: 1,
        key: "1b",
        mini: `${case2_1mini}`,
        big: `${case2_1}`,
        miniwebp: `${case2_1miniwebp}`,
        miniavif: `${case2_1miniavif}`,
        bigwebp: `${case2_1webp}`,
        bigavif: `${case2_1avif}`,
      },
      {
        number: 2,
        key: "2b",
        mini: `${case2_2mini}`,
        big: `${case2_2}`,
        miniwebp: `${case2_2miniwebp}`,
        miniavif: `${case2_2miniavif}`,
        bigwebp: `${case2_2webp}`,
        bigavif: `${case2_2avif}`,
      },
      {
        number: 3,
        key: "3b",
        mini: `${case2_3mini}`,
        big: `${case2_3}`,
        miniwebp: `${case2_3miniwebp}`,
        miniavif: `${case2_3miniavif}`,
        bigwebp: `${case2_3webp}`,
        bigavif: `${case2_3avif}`,
      },
      {
        number: 4,
        key: "4b",
        mini: `${case2_4mini}`,
        big: `${case2_4}`,
        miniwebp: `${case2_4miniwebp}`,
        miniavif: `${case2_4miniavif}`,
        bigwebp: `${case2_4webp}`,
        bigavif: `${case2_4avif}`,
      },
      {
        number: 5,
        key: "5b",
        mini: `${case2_5mini}`,
        big: `${case2_5}`,
        miniwebp: `${case2_5miniwebp}`,
        miniavif: `${case2_5miniavif}`,
        bigwebp: `${case2_5webp}`,
        bigavif: `${case2_5avif}`,
      },
      {
        number: 6,
        key: "6b",
        mini: `${case2_6mini}`,
        big: `${case2_6}`,
        miniwebp: `${case2_6miniwebp}`,
        miniavif: `${case2_6miniavif}`,
        bigwebp: `${case2_6webp}`,
        bigavif: `${case2_6avif}`,
      },
      {
        number: 7,
        key: "7b",
        mini: `${case2_7mini}`,
        big: `${case2_7}`,
        miniwebp: `${case2_7miniwebp}`,
        miniavif: `${case2_7miniavif}`,
        bigwebp: `${case2_7webp}`,
        bigavif: `${case2_7avif}`,
      },
      {
        number: 8,
        key: "8b",
        mini: `${case2_8mini}`,
        big: `${case2_8}`,
        miniwebp: `${case2_8miniwebp}`,
        miniavif: `${case2_8miniavif}`,
        bigwebp: `${case2_8webp}`,
        bigavif: `${case2_8avif}`,
      },
      {
        number: 9,
        key: "9b",
        mini: `${case2_9mini}`,
        big: `${case2_9}`,
        miniwebp: `${case2_9miniwebp}`,
        miniavif: `${case2_9miniavif}`,
        bigwebp: `${case2_9webp}`,
        bigavif: `${case2_9avif}`,
      },
      {
        number: 10,
        key: "10b",
        mini: `${case2_10mini}`,
        big: `${case2_10}`,
        miniwebp: `${case2_10miniwebp}`,
        miniavif: `${case2_10miniavif}`,
        bigwebp: `${case2_10webp}`,
        bigavif: `${case2_10avif}`,
      },
      {
        number: 11,
        key: "11b",
        mini: `${case2_11mini}`,
        big: `${case2_11}`,
        miniwebp: `${case2_11miniwebp}`,
        miniavif: `${case2_11miniavif}`,
        bigwebp: `${case2_11webp}`,
        bigavif: `${case2_11avif}`,
      },
      {
        number: 12,
        key: "12b",
        mini: `${case2_12mini}`,
        big: `${case2_12}`,
        miniwebp: `${case2_12miniwebp}`,
        miniavif: `${case2_12miniavif}`,
        bigwebp: `${case2_12webp}`,
        bigavif: `${case2_12avif}`,
      },
      {
        number: 13,
        key: "13b",
        mini: `${case2_13mini}`,
        big: `${case2_13}`,
        miniwebp: `${case2_13miniwebp}`,
        miniavif: `${case2_13miniavif}`,
        bigwebp: `${case2_13webp}`,
        bigavif: `${case2_13avif}`,
      },
      {
        number: 14,
        key: "14b",
        mini: `${case2_14mini}`,
        big: `${case2_14}`,
        miniwebp: `${case2_14miniwebp}`,
        miniavif: `${case2_14miniavif}`,
        bigwebp: `${case2_14webp}`,
        bigavif: `${case2_14avif}`,
      },
      {
        number: 15,
        key: "15b",
        mini: `${case2_15mini}`,
        big: `${case2_15}`,
        miniwebp: `${case2_15miniwebp}`,
        miniavif: `${case2_15miniavif}`,
        bigwebp: `${case2_15webp}`,
        bigavif: `${case2_15avif}`,
      },
      {
        number: 16,
        key: "16b",
        mini: `${case2_16mini}`,
        big: `${case2_16}`,
        miniwebp: `${case2_16miniwebp}`,
        miniavif: `${case2_16miniavif}`,
        bigwebp: `${case2_16webp}`,
        bigavif: `${case2_16avif}`,
      },
      {
        number: 17,
        key: "17b",
        mini: `${case2_17mini}`,
        big: `${case2_17}`,
        miniwebp: `${case2_17miniwebp}`,
        miniavif: `${case2_17miniavif}`,
        bigwebp: `${case2_17webp}`,
        bigavif: `${case2_17avif}`,
      },
      {
        number: 18,
        key: "18b",
        mini: `${case2_18mini}`,
        big: `${case2_18}`,
        miniwebp: `${case2_18miniwebp}`,
        miniavif: `${case2_18miniavif}`,
        bigwebp: `${case2_18webp}`,
        bigavif: `${case2_18avif}`,
      },
      {
        number: 19,
        key: "19b",
        mini: `${case2_19mini}`,
        big: `${case2_19}`,
        miniwebp: `${case2_19miniwebp}`,
        miniavif: `${case2_19miniavif}`,
        bigwebp: `${case2_19webp}`,
        bigavif: `${case2_19avif}`,
      },
      {
        number: 20,
        key: "20b",
        mini: `${case2_20mini}`,
        big: `${case2_20}`,
        miniwebp: `${case2_20miniwebp}`,
        miniavif: `${case2_20miniavif}`,
        bigwebp: `${case2_20webp}`,
        bigavif: `${case2_20avif}`,
      },
      {
        number: 21,
        key: "21b",
        mini: `${case2_21mini}`,
        big: `${case2_21}`,
        miniwebp: `${case2_21miniwebp}`,
        miniavif: `${case2_21miniavif}`,
        bigwebp: `${case2_21webp}`,
        bigavif: `${case2_21avif}`,
      },
      {
        number: 22,
        key: "22b",
        mini: `${case2_22mini}`,
        big: `${case2_22}`,
        miniwebp: `${case2_22miniwebp}`,
        miniavif: `${case2_22miniavif}`,
        bigwebp: `${case2_22webp}`,
        bigavif: `${case2_22avif}`,
      },
    ],
    title: "Дом из газобетона, 102 м2, Деревня Назия",
    text: [
      {
        key: "1b",
        title: "Отопление:",
        subtitle: [
          "1 этаж:",
          "56 м2 теплого пола с матами 50 мм. 5 контуров.",
          "Ростверк заполнили пеноплексом, общая толщина 250 мм",
          "Утепление под трубой 300 мм. Общая толщина пирога со стяжкой 370 мм",
          "Выведен радиатор в прихожую, и подключен полотенцесушитель от радиаторного отопления в с/у 1 этажа",
          "Выполнен монтаж полусухой стяжки пола",
          "2 этаж:",
          "Смонтировано 3 радиатора в 3 спальни, и 1 полотенцесушитель в с/у 2 этажа",
        ],
      },
      {
        key: "2b",
        title: "Водоснабжение и канализация:",
        subtitle: [
          "Выполнен перенос трубы ПНД32 ввода воды ХВС  из кухни в котельную под лестницей",
          "Разводка водоснабжения и канализации на санузлы 1 и 2 этажей, и кухню. Разводка выполнена через коллектор из металлополимерной трубы. Трубы спрятаны в стяжку и вштроблены в стены, в дальнейшем скроются плиткой",
          "Смонтирован вводной узел ХВС. С установкой счетчика и магистрального фильтра. Для избавления от резких скачков давления, был установлен компенсатор гидроударов",
        ],
      },
      {
        key: "3b",
        title: "Котельная:",
        subtitle: [
          "Котельная организована под лестницей, использовали все пространство по максимуму",
          "Смонтирован электрический котел Protherm Skat 9 КВт",
          "Установлен и настроен GSM модуль Zont. Котел управляется с помощью с помощью смартфона. Что позволяет значительно экономить электроэнергию",
          "Смонтирован бойлер косвенного нагрева Stout 150 л., на специальную подставку. Бойлер подключен к котлу через трехходовой клапан Fugas. Время нагрева бойлера до 50-60 гр. 40 мин",
          "Теплый пол работают напрямую от котлового насоса",
          "Гидравлическая обвязка котельной выполнена полипропиленом",
        ],
      },
    ],
    type: "газобетон",
  },
  {
    key: 3,
    srcMain: `${case3_1}`,
    srcMainWebp: `${case3_1webp}`,
    srcMainAvif: `${case3_1avif}`,
    src: [
      {
        number: 1,
        key: "1c",
        mini: `${case3_1mini}`,
        big: `${case3_1}`,
        miniwebp: `${case3_1miniwebp}`,
        miniavif: `${case3_1miniavif}`,
        bigwebp: `${case3_1webp}`,
        bigavif: `${case3_1avif}`,
      },
      {
        number: 2,
        key: "2c",
        mini: `${case3_2mini}`,
        big: `${case3_2}`,
        miniwebp: `${case3_2miniwebp}`,
        miniavif: `${case3_2miniavif}`,
        bigwebp: `${case3_2webp}`,
        bigavif: `${case3_2avif}`,
      },
      {
        number: 3,
        key: "3c",
        mini: `${case3_3mini}`,
        big: `${case3_3}`,
        miniwebp: `${case3_3miniwebp}`,
        miniavif: `${case3_3miniavif}`,
        bigwebp: `${case3_3webp}`,
        bigavif: `${case3_3avif}`,
      },
      {
        number: 4,
        key: "4c",
        mini: `${case3_4mini}`,
        big: `${case3_4}`,
        miniwebp: `${case3_4miniwebp}`,
        miniavif: `${case3_4miniavif}`,
        bigwebp: `${case3_4webp}`,
        bigavif: `${case3_4avif}`,
      },
      {
        number: 5,
        key: "5c",
        mini: `${case3_5mini}`,
        big: `${case3_5}`,
        miniwebp: `${case3_5miniwebp}`,
        miniavif: `${case3_5miniavif}`,
        bigwebp: `${case3_5webp}`,
        bigavif: `${case3_5avif}`,
      },
      {
        number: 6,
        key: "6c",
        mini: `${case3_6mini}`,
        big: `${case3_6}`,
        miniwebp: `${case3_6miniwebp}`,
        miniavif: `${case3_6miniavif}`,
        bigwebp: `${case3_6webp}`,
        bigavif: `${case3_6avif}`,
      },
      {
        number: 7,
        key: "7c",
        mini: `${case3_7mini}`,
        big: `${case3_7}`,
        miniwebp: `${case3_7miniwebp}`,
        miniavif: `${case3_7miniavif}`,
        bigwebp: `${case3_7webp}`,
        bigavif: `${case3_7avif}`,
      },
      {
        number: 8,
        key: "8c",
        mini: `${case3_8mini}`,
        big: `${case3_8}`,
        miniwebp: `${case3_8miniwebp}`,
        miniavif: `${case3_8miniavif}`,
        bigwebp: `${case3_8webp}`,
        bigavif: `${case3_8avif}`,
      },
      {
        number: 9,
        key: "9c",
        mini: `${case3_9mini}`,
        big: `${case3_9}`,
        miniwebp: `${case3_9miniwebp}`,
        miniavif: `${case3_9miniavif}`,
        bigwebp: `${case3_9webp}`,
        bigavif: `${case3_9avif}`,
      },
      {
        number: 10,
        key: "10c",
        mini: `${case3_10mini}`,
        big: `${case3_10}`,
        miniwebp: `${case3_10miniwebp}`,
        miniavif: `${case3_10miniavif}`,
        bigwebp: `${case3_10webp}`,
        bigavif: `${case3_10avif}`,
      },
      {
        number: 11,
        key: "11c",
        mini: `${case3_11mini}`,
        big: `${case3_11}`,
        miniwebp: `${case3_11miniwebp}`,
        miniavif: `${case3_11miniavif}`,
        bigwebp: `${case3_11webp}`,
        bigavif: `${case3_11avif}`,
      },
      {
        number: 12,
        key: "12c",
        mini: `${case3_12mini}`,
        big: `${case3_12}`,
        miniwebp: `${case3_12miniwebp}`,
        miniavif: `${case3_12miniavif}`,
        bigwebp: `${case3_12webp}`,
        bigavif: `${case3_12avif}`,
      },
      {
        number: 13,
        key: "13c",
        mini: `${case3_13mini}`,
        big: `${case3_13}`,
        miniwebp: `${case3_13miniwebp}`,
        miniavif: `${case3_13miniavif}`,
        bigwebp: `${case3_13webp}`,
        bigavif: `${case3_13avif}`,
      },
      {
        number: 14,
        key: "14c",
        mini: `${case3_14mini}`,
        big: `${case3_14}`,
        miniwebp: `${case3_14miniwebp}`,
        miniavif: `${case3_14miniavif}`,
        bigwebp: `${case3_14webp}`,
        bigavif: `${case3_14avif}`,
      },
      {
        number: 15,
        key: "15c",
        mini: `${case3_15mini}`,
        big: `${case3_15}`,
        miniwebp: `${case3_15miniwebp}`,
        miniavif: `${case3_15miniavif}`,
        bigwebp: `${case3_15webp}`,
        bigavif: `${case3_15avif}`,
      },
      {
        number: 16,
        key: "16c",
        mini: `${case3_16mini}`,
        big: `${case3_16}`,
        miniwebp: `${case3_16miniwebp}`,
        miniavif: `${case3_16miniavif}`,
        bigwebp: `${case3_16webp}`,
        bigavif: `${case3_16avif}`,
      },
      {
        number: 17,
        key: "17c",
        mini: `${case3_17mini}`,
        big: `${case3_17}`,
        miniwebp: `${case3_17miniwebp}`,
        miniavif: `${case3_17miniavif}`,
        bigwebp: `${case3_17webp}`,
        bigavif: `${case3_17avif}`,
      },
      {
        number: 18,
        key: "18c",
        mini: `${case3_18mini}`,
        big: `${case3_18}`,
        miniwebp: `${case3_18miniwebp}`,
        miniavif: `${case3_18miniavif}`,
        bigwebp: `${case3_18webp}`,
        bigavif: `${case3_18avif}`,
      },
      {
        number: 19,
        key: "19c",
        mini: `${case3_19mini}`,
        big: `${case3_19}`,
        miniwebp: `${case3_19miniwebp}`,
        miniavif: `${case3_19miniavif}`,
        bigwebp: `${case3_19webp}`,
        bigavif: `${case3_19avif}`,
      },
      {
        number: 20,
        key: "20c",
        mini: `${case3_20mini}`,
        big: `${case3_20}`,
        miniwebp: `${case3_20miniwebp}`,
        miniavif: `${case3_20miniavif}`,
        bigwebp: `${case3_20webp}`,
        bigavif: `${case3_20avif}`,
      },
      {
        number: 21,
        key: "21c",
        mini: `${case3_21mini}`,
        big: `${case3_21}`,
        miniwebp: `${case3_21miniwebp}`,
        miniavif: `${case3_21miniavif}`,
        bigwebp: `${case3_21webp}`,
        bigavif: `${case3_21avif}`,
      },
      {
        number: 22,
        key: "22c",
        mini: `${case3_22mini}`,
        big: `${case3_22}`,
        miniwebp: `${case3_22miniwebp}`,
        miniavif: `${case3_22miniavif}`,
        bigwebp: `${case3_22webp}`,
        bigavif: `${case3_22avif}`,
      },
      {
        number: 23,
        key: "23c",
        mini: `${case3_23mini}`,
        big: `${case3_23}`,
        miniwebp: `${case3_23miniwebp}`,
        miniavif: `${case3_23miniavif}`,
        bigwebp: `${case3_23webp}`,
        bigavif: `${case3_23avif}`,
      },
      {
        number: 24,
        key: "24c",
        mini: `${case3_24mini}`,
        big: `${case3_24}`,
        miniwebp: `${case3_24miniwebp}`,
        miniavif: `${case3_24miniavif}`,
        bigwebp: `${case3_24webp}`,
        bigavif: `${case3_24avif}`,
      },
      {
        number: 25,
        key: "25c",
        mini: `${case3_25mini}`,
        big: `${case3_25}`,
        miniwebp: `${case3_25miniwebp}`,
        miniavif: `${case3_25miniavif}`,
        bigwebp: `${case3_25webp}`,
        bigavif: `${case3_25avif}`,
      },
    ],
    title: "Дом из кирпича, 162 м2, Ульяновка",
    text: [
      {
        key: "1c",
        title: "Отопление:",
        subtitle: [
          "1 этаж:",
          "87 м2 теплого пола с матами. 7 контуров",
          "Утепление под трубой 50 мм. Общая толщина пирога со стяжкой 120 мм",
          "2 этаж:",
          "75 м2 теплого пола с матами. 6 контуров",
          "Сделали монтаж кабеля под комнатные термостаты и гильзу под датчик температуры стяжки",
          "Выполнен монтаж полусухой стяжки пола на 2 этажах за 1 день",
          "Вторым этапом установили комнатные термостаты, датчики температуры пола и блоки управления. Теперь заказчик может зонально регулировать температуру воздуха в каждом помещение",
        ],
      },
      {
        key: "2c",
        title: "Котельная:",
        subtitle: [
          "Смонтирован Газовый котел Bosch GAZ WBN6000 HRN 24 КВт",
          "Был установлен GSM модуль, для управления системой отопления с помощью смартфона",
          "Смонтирован бойлер косвенного нагрева Nibe 200 л., с баком и нержавеющей стали. Бойлер подключен к котлу через встроенный трехходовой клапан. Время нагрева бойлера до 50-60 гр. 40 мин",
          "На подачу теплоносителя в теплый пол 2-х этажей, смонтирован один трехходовой смесительный клапан с увеличенным KVS",
          "Гидравлическая обвязка котельной выполнена полипропиленом",
        ],
      },
    ],
    type: "кирпич",
  },
  {
    key: 4,
    srcMain: `${case4_1}`,
    srcMainWebp: `${case4_1webp}`,
    srcMainAvif: `${case4_1avif}`,
    src: [
      {
        number: 1,
        key: "1d",
        mini: `${case4_1mini}`,
        big: `${case4_1}`,
        miniwebp: `${case4_1miniwebp}`,
        miniavif: `${case4_1miniavif}`,
        bigwebp: `${case4_1webp}`,
        bigavif: `${case4_1avif}`,
      },
      {
        number: 2,
        key: "2d",
        mini: `${case4_2mini}`,
        big: `${case4_2}`,
        miniwebp: `${case4_2miniwebp}`,
        miniavif: `${case4_2miniavif}`,
        bigwebp: `${case4_2webp}`,
        bigavif: `${case4_2avif}`,
      },
      {
        number: 3,
        key: "3d",
        mini: `${case4_3mini}`,
        big: `${case4_3}`,
        miniwebp: `${case4_3miniwebp}`,
        miniavif: `${case4_3miniavif}`,
        bigwebp: `${case4_3webp}`,
        bigavif: `${case4_3avif}`,
      },
      {
        number: 4,
        key: "4d",
        mini: `${case4_4mini}`,
        big: `${case4_4}`,
        miniwebp: `${case4_4miniwebp}`,
        miniavif: `${case4_4miniavif}`,
        bigwebp: `${case4_4webp}`,
        bigavif: `${case4_4avif}`,
      },
      {
        number: 5,
        key: "5d",
        mini: `${case4_5mini}`,
        big: `${case4_5}`,
        miniwebp: `${case4_5miniwebp}`,
        miniavif: `${case4_5miniavif}`,
        bigwebp: `${case4_5webp}`,
        bigavif: `${case4_5avif}`,
      },
      {
        number: 6,
        key: "6d",
        mini: `${case4_6mini}`,
        big: `${case4_6}`,
        miniwebp: `${case4_6miniwebp}`,
        miniavif: `${case4_6miniavif}`,
        bigwebp: `${case4_6webp}`,
        bigavif: `${case4_6avif}`,
      },
      {
        number: 7,
        key: "7d",
        mini: `${case4_7mini}`,
        big: `${case4_7}`,
        miniwebp: `${case4_7miniwebp}`,
        miniavif: `${case4_7miniavif}`,
        bigwebp: `${case4_7webp}`,
        bigavif: `${case4_7avif}`,
      },
      {
        number: 8,
        key: "8d",
        mini: `${case4_8mini}`,
        big: `${case4_8}`,
        miniwebp: `${case4_8miniwebp}`,
        miniavif: `${case4_8miniavif}`,
        bigwebp: `${case4_8webp}`,
        bigavif: `${case4_8avif}`,
      },
      {
        number: 9,
        key: "9d",
        mini: `${case4_9mini}`,
        big: `${case4_9}`,
        miniwebp: `${case4_9miniwebp}`,
        miniavif: `${case4_9miniavif}`,
        bigwebp: `${case4_9webp}`,
        bigavif: `${case4_9avif}`,
      },
      {
        number: 10,
        key: "10d",
        mini: `${case4_10mini}`,
        big: `${case4_10}`,
        miniwebp: `${case4_10miniwebp}`,
        miniavif: `${case4_10miniavif}`,
        bigwebp: `${case4_10webp}`,
        bigavif: `${case4_10avif}`,
      },
      {
        number: 11,
        key: "11d",
        mini: `${case4_11mini}`,
        big: `${case4_11}`,
        miniwebp: `${case4_11miniwebp}`,
        miniavif: `${case4_11miniavif}`,
        bigwebp: `${case4_11webp}`,
        bigavif: `${case4_11avif}`,
      },
      {
        number: 12,
        key: "12d",
        mini: `${case4_12mini}`,
        big: `${case4_12}`,
        miniwebp: `${case4_12miniwebp}`,
        miniavif: `${case4_12miniavif}`,
        bigwebp: `${case4_12webp}`,
        bigavif: `${case4_12avif}`,
      },
      {
        number: 13,
        key: "13d",
        mini: `${case4_13mini}`,
        big: `${case4_13}`,
        miniwebp: `${case4_13miniwebp}`,
        miniavif: `${case4_13miniavif}`,
        bigwebp: `${case4_13webp}`,
        bigavif: `${case4_13avif}`,
      },
      {
        number: 14,
        key: "14d",
        mini: `${case4_14mini}`,
        big: `${case4_14}`,
        miniwebp: `${case4_14miniwebp}`,
        miniavif: `${case4_14miniavif}`,
        bigwebp: `${case4_14webp}`,
        bigavif: `${case4_14avif}`,
      },
      {
        number: 15,
        key: "15d",
        mini: `${case4_15mini}`,
        big: `${case4_15}`,
        miniwebp: `${case4_15miniwebp}`,
        miniavif: `${case4_15miniavif}`,
        bigwebp: `${case4_15webp}`,
        bigavif: `${case4_15avif}`,
      },
      {
        number: 16,
        key: "16d",
        mini: `${case4_16mini}`,
        big: `${case4_16}`,
        miniwebp: `${case4_16miniwebp}`,
        miniavif: `${case4_16miniavif}`,
        bigwebp: `${case4_16webp}`,
        bigavif: `${case4_16avif}`,
      },
      {
        number: 17,
        key: "17d",
        mini: `${case4_17mini}`,
        big: `${case4_17}`,
        miniwebp: `${case4_17miniwebp}`,
        miniavif: `${case4_17miniavif}`,
        bigwebp: `${case4_17webp}`,
        bigavif: `${case4_17avif}`,
      },
      {
        number: 18,
        key: "18d",
        mini: `${case4_18mini}`,
        big: `${case4_18}`,
        miniwebp: `${case4_18miniwebp}`,
        miniavif: `${case4_18miniavif}`,
        bigwebp: `${case4_18webp}`,
        bigavif: `${case4_18avif}`,
      },
      {
        number: 19,
        key: "19d",
        mini: `${case4_19mini}`,
        big: `${case4_19}`,
        miniwebp: `${case4_19miniwebp}`,
        miniavif: `${case4_19miniavif}`,
        bigwebp: `${case4_19webp}`,
        bigavif: `${case4_19avif}`,
      },
      {
        number: 20,
        key: "20d",
        mini: `${case4_20mini}`,
        big: `${case4_20}`,
        miniwebp: `${case4_20miniwebp}`,
        miniavif: `${case4_20miniavif}`,
        bigwebp: `${case4_20webp}`,
        bigavif: `${case4_20avif}`,
      },
      {
        number: 21,
        key: "21d",
        mini: `${case4_21mini}`,
        big: `${case4_21}`,
        miniwebp: `${case4_21miniwebp}`,
        miniavif: `${case4_21miniavif}`,
        bigwebp: `${case4_21webp}`,
        bigavif: `${case4_21avif}`,
      },
      {
        number: 22,
        key: "22d",
        mini: `${case4_22mini}`,
        big: `${case4_22}`,
        miniwebp: `${case4_22miniwebp}`,
        miniavif: `${case4_22miniavif}`,
        bigwebp: `${case4_22webp}`,
        bigavif: `${case4_22avif}`,
      },
      {
        number: 23,
        key: "23d",
        mini: `${case4_23mini}`,
        big: `${case4_23}`,
        miniwebp: `${case4_23miniwebp}`,
        miniavif: `${case4_23miniavif}`,
        bigwebp: `${case4_23webp}`,
        bigavif: `${case4_23avif}`,
      },
      {
        number: 24,
        key: "24d",
        mini: `${case4_24mini}`,
        big: `${case4_24}`,
        miniwebp: `${case4_24miniwebp}`,
        miniavif: `${case4_24miniavif}`,
        bigwebp: `${case4_24webp}`,
        bigavif: `${case4_24avif}`,
      },
      {
        number: 25,
        key: "25d",
        mini: `${case4_25mini}`,
        big: `${case4_25}`,
        miniwebp: `${case4_25miniwebp}`,
        miniavif: `${case4_25miniavif}`,
        bigwebp: `${case4_25webp}`,
        bigavif: `${case4_25avif}`,
      },
      {
        number: 26,
        key: "26d",
        mini: `${case4_26mini}`,
        big: `${case4_26}`,
        miniwebp: `${case4_26miniwebp}`,
        miniavif: `${case4_26miniavif}`,
        bigwebp: `${case4_26webp}`,
        bigavif: `${case4_26avif}`,
      },
      {
        number: 27,
        key: "27d",
        mini: `${case4_27mini}`,
        big: `${case4_27}`,
        miniwebp: `${case4_27miniwebp}`,
        miniavif: `${case4_27miniavif}`,
        bigwebp: `${case4_27webp}`,
        bigavif: `${case4_27avif}`,
      },
      {
        number: 28,
        key: "28d",
        mini: `${case4_28mini}`,
        big: `${case4_28}`,
        miniwebp: `${case4_28miniwebp}`,
        miniavif: `${case4_28miniavif}`,
        bigwebp: `${case4_28webp}`,
        bigavif: `${case4_28avif}`,
      },
      {
        number: 29,
        key: "29d",
        mini: `${case4_29mini}`,
        big: `${case4_29}`,
        miniwebp: `${case4_29miniwebp}`,
        miniavif: `${case4_29miniavif}`,
        bigwebp: `${case4_29webp}`,
        bigavif: `${case4_29avif}`,
      },
      {
        number: 30,
        key: "30d",
        mini: `${case4_30mini}`,
        big: `${case4_30}`,
        miniwebp: `${case4_30miniwebp}`,
        miniavif: `${case4_30miniavif}`,
        bigwebp: `${case4_30webp}`,
        bigavif: `${case4_30avif}`,
      },
      {
        number: 31,
        key: "31d",
        mini: `${case4_31mini}`,
        big: `${case4_31}`,
        miniwebp: `${case4_31miniwebp}`,
        miniavif: `${case4_31miniavif}`,
        bigwebp: `${case4_31webp}`,
        bigavif: `${case4_31avif}`,
      },
      {
        number: 32,
        key: "32d",
        mini: `${case4_32mini}`,
        big: `${case4_32}`,
        miniwebp: `${case4_32miniwebp}`,
        miniavif: `${case4_32miniavif}`,
        bigwebp: `${case4_32webp}`,
        bigavif: `${case4_32avif}`,
      },
      {
        number: 33,
        key: "33d",
        mini: `${case4_33mini}`,
        big: `${case4_33}`,
        miniwebp: `${case4_33miniwebp}`,
        miniavif: `${case4_33miniavif}`,
        bigwebp: `${case4_33webp}`,
        bigavif: `${case4_33avif}`,
      },
    ],
    title: "Дом из сип панелей, 165 м2, КП Вуокса Плюс",
    text: [
      {
        key: "1d",
        title: "Отопление:",
        subtitle: [
          "1 этаж:",
          "86 м2 облегченного водяного теплого пола на фольгированные элементы, 9 контуров",
          "Реализовали такое решение теплого пола, т.к. фундамент и перекрытие 1 этажа не рассчитано на вес полусухой стяжки. Данная система в стяжки не нуждается, сразу на фольгированный можно класть чистовое покрытие",
          "Пришлось дополнять 1 этаж радиаторами под окнами, потому что одного теплого пола в холодные дни не будет хватать",
        ],
      },
      {
        key: "2d",
        title: "Водоснабжение и канализация:",
        subtitle: [
          "Выполнена разводка труб водоснабжения и канализации на 2 полноценных санузла и кухню",
          "Разводка выполнена коллекторным способом и рециркуляцией ГВС. Заказчик будет получать горячую воду моментально и крана",
          "Трубы водоснабжения и канализации спрятаны в тело пола и стен, что дает максимально возможный эстетический вид после отделки помещений",
          "Подготовлен узел ХВС для центрального водоснабжения",
          "Выполнен монтаж автономной канализации Зорде 7",
        ],
      },
      {
        key: "3d",
        title: "Котельная:",
        subtitle: [
          "Во главе системы установлены 2 котла, электрический Protherm и газовый Buderus. Первое время пока нет газа будет работать электрический Protherm. После появления газа, основным станет газовый котел Buderus, а электрический станет резервным.",
          "Электрический котел будет управлять с помощью GSM модуля Zont. Что даст заказчику экономию электроэнергии, и современное управление системой отопления со смартфона.",
          "Бойлер косвенного нагрева Stout 200 л. сможет нагревать с помощью электрического или газового котла.",
        ],
      },
    ],
    type: "панели",
  },
  {
    key: 5,
    srcMain: `${case5_1}`,
    srcMainWebp: `${case5_1webp}`,
    srcMainAvif: `${case5_1avif}`,
    src: [
      {
        number: 1,
        key: "1e",
        mini: `${case5_1mini}`,
        big: `${case5_1}`,
        miniwebp: `${case5_1miniwebp}`,
        miniavif: `${case5_1miniavif}`,
        bigwebp: `${case5_1webp}`,
        bigavif: `${case5_1avif}`,
      },
      {
        number: 2,
        key: "2e",
        mini: `${case5_2mini}`,
        big: `${case5_2}`,
        miniwebp: `${case5_2miniwebp}`,
        miniavif: `${case5_2miniavif}`,
        bigwebp: `${case5_2webp}`,
        bigavif: `${case5_2avif}`,
      },
      {
        number: 3,
        key: "3e",
        mini: `${case5_3mini}`,
        big: `${case5_3}`,
        miniwebp: `${case5_3miniwebp}`,
        miniavif: `${case5_3miniavif}`,
        bigwebp: `${case5_3webp}`,
        bigavif: `${case5_3avif}`,
      },
      {
        number: 4,
        key: "4e",
        mini: `${case5_4mini}`,
        big: `${case5_4}`,
        miniwebp: `${case5_4miniwebp}`,
        miniavif: `${case5_4miniavif}`,
        bigwebp: `${case5_4webp}`,
        bigavif: `${case5_4avif}`,
      },
      {
        number: 5,
        key: "5e",
        mini: `${case5_5mini}`,
        big: `${case5_5}`,
        miniwebp: `${case5_5miniwebp}`,
        miniavif: `${case5_5miniavif}`,
        bigwebp: `${case5_5webp}`,
        bigavif: `${case5_5avif}`,
      },
      {
        number: 6,
        key: "6e",
        mini: `${case5_6mini}`,
        big: `${case5_6}`,
        miniwebp: `${case5_6miniwebp}`,
        miniavif: `${case5_6miniavif}`,
        bigwebp: `${case5_6webp}`,
        bigavif: `${case5_6avif}`,
      },
      {
        number: 7,
        key: "7e",
        mini: `${case5_7mini}`,
        big: `${case5_7}`,
        miniwebp: `${case5_7miniwebp}`,
        miniavif: `${case5_7miniavif}`,
        bigwebp: `${case5_7webp}`,
        bigavif: `${case5_7avif}`,
      },
      {
        number: 8,
        key: "8e",
        mini: `${case5_8mini}`,
        big: `${case5_8}`,
        miniwebp: `${case5_8miniwebp}`,
        miniavif: `${case5_8miniavif}`,
        bigwebp: `${case5_8webp}`,
        bigavif: `${case5_8avif}`,
      },
      {
        number: 9,
        key: "9e",
        mini: `${case5_9mini}`,
        big: `${case5_9}`,
        miniwebp: `${case5_9miniwebp}`,
        miniavif: `${case5_9miniavif}`,
        bigwebp: `${case5_9webp}`,
        bigavif: `${case5_9avif}`,
      },
      {
        number: 10,
        key: "10e",
        mini: `${case5_10mini}`,
        big: `${case5_10}`,
        miniwebp: `${case5_10miniwebp}`,
        miniavif: `${case5_10miniavif}`,
        bigwebp: `${case5_10webp}`,
        bigavif: `${case5_10avif}`,
      },
      {
        number: 11,
        key: "11e",
        mini: `${case5_11mini}`,
        big: `${case5_11}`,
        miniwebp: `${case5_11miniwebp}`,
        miniavif: `${case5_11miniavif}`,
        bigwebp: `${case5_11webp}`,
        bigavif: `${case5_11avif}`,
      },
      {
        number: 12,
        key: "12e",
        mini: `${case5_12mini}`,
        big: `${case5_12}`,
        miniwebp: `${case5_12miniwebp}`,
        miniavif: `${case5_12miniavif}`,
        bigwebp: `${case5_12webp}`,
        bigavif: `${case5_12avif}`,
      },
      {
        number: 13,
        key: "13e",
        mini: `${case5_13mini}`,
        big: `${case5_13}`,
        miniwebp: `${case5_13miniwebp}`,
        miniavif: `${case5_13miniavif}`,
        bigwebp: `${case5_13webp}`,
        bigavif: `${case5_13avif}`,
      },
      {
        number: 14,
        key: "14e",
        mini: `${case5_14mini}`,
        big: `${case5_14}`,
        miniwebp: `${case5_14miniwebp}`,
        miniavif: `${case5_14miniavif}`,
        bigwebp: `${case5_14webp}`,
        bigavif: `${case5_14avif}`,
      },
      {
        number: 15,
        key: "15e",
        mini: `${case5_15mini}`,
        big: `${case5_15}`,
        miniwebp: `${case5_15miniwebp}`,
        miniavif: `${case5_15miniavif}`,
        bigwebp: `${case5_15webp}`,
        bigavif: `${case5_15avif}`,
      },
      {
        number: 16,
        key: "16e",
        mini: `${case5_16mini}`,
        big: `${case5_16}`,
        miniwebp: `${case5_16miniwebp}`,
        miniavif: `${case5_16miniavif}`,
        bigwebp: `${case5_16webp}`,
        bigavif: `${case5_16avif}`,
      },
      {
        number: 17,
        key: "17e",
        mini: `${case5_17mini}`,
        big: `${case5_17}`,
        miniwebp: `${case5_17miniwebp}`,
        miniavif: `${case5_17miniavif}`,
        bigwebp: `${case5_17webp}`,
        bigavif: `${case5_17avif}`,
      },
      {
        number: 18,
        key: "18e",
        mini: `${case5_18mini}`,
        big: `${case5_18}`,
        miniwebp: `${case5_18miniwebp}`,
        miniavif: `${case5_18miniavif}`,
        bigwebp: `${case5_18webp}`,
        bigavif: `${case5_18avif}`,
      },
    ],
    title: "Дом из велокса, 102 м2, КП Савоя",
    text: [
      {
        key: "1e",
        title: "Отопление:",
        subtitle: [
          "1 этаж:",
          "124 м2 теплого пола с матами 50 мм. 12 контуров.",
          "Под панорамными окнами сделали «краевые высокотемпературные зоны», под окнами пол будет греться до 50-60 гр. в холодные дни.",
          "2 этаж:",
          "85 м2 теплого пола с матами 25 мм. 9 контуров.",
          "Выполнен монтаж полусухой стяжки пола на 2-х этажах за 1 день.",
        ],
      },
      {
        key: "2e",
        title: "Водоснабжение и канализация:",
        subtitle: [
          "Выполнена разводка труб водоснабжения и канализации на 2 полноценных санузла и кухню.",
          "Разводка выполнена коллекторным способом и рециркуляцией ГВС. Заказчик будет получать горячую воду моментально из крана.",
          "Трубы водоснабжения и канализации спрятаны в тело стяжки и стен, что дает максимально возможный эстетический вид после отделки помещений.",
          "Подготовлен узел ХВС для скважинного насоса. После бурения скважины и монтажа насоса, останется только открыть кран.",
        ],
      },
      {
        key: "3e",
        title: "Котельная:",
        subtitle: [
          "В основе системы отопления газовый котел De Dietrich MSL 31 FF.",
          "Он подключен к 3 насосным группам через гидравлическую стрелку.",
          "1 насосная группа управляет теплым полом 1 этажа, 2 группа – теплым полом 2 этажа, а 3 насосная группа управляет краевыми зонами.",
          "Бойлер косвенного нагрева Stout 300 л., нагревается напрямую от газового котла с помощью встроенного трехходового клапана. Бойлер установили предварительно на подставку.",
          "Управляется вся система отопления с помощью отопительного контроллера Zont 2000+. Он позволяет управлять со смартфона теплым полом на каждом этаже, и краевой зоной. ",
          "В доме будут 2 комнатных радио датчика на каждом этаже, и уличный радио датчик. Есть возможность задать различные режимы управления «комфорт», «отпуск», «расписание» т .д.",
        ],
      },
    ],
    type: "велокс",
  },
  {
    key: 6,
    srcMain: `${case6_1}`,
    srcMainWebp: `${case6_1webp}`,
    srcMainAvif: `${case6_1avif}`,
    src: [
      {
        number: 1,
        key: "1f",
        mini: `${case6_1mini}`,
        big: `${case6_1}`,
        miniwebp: `${case6_1miniwebp}`,
        miniavif: `${case6_1miniavif}`,
        bigwebp: `${case6_1webp}`,
        bigavif: `${case6_1avif}`,
      },
      {
        number: 2,
        key: "2f",
        mini: `${case6_2mini}`,
        big: `${case6_2}`,
        miniwebp: `${case6_2miniwebp}`,
        miniavif: `${case6_2miniavif}`,
        bigwebp: `${case6_2webp}`,
        bigavif: `${case6_2avif}`,
      },
      {
        number: 3,
        key: "3f",
        mini: `${case6_3mini}`,
        big: `${case6_3}`,
        miniwebp: `${case6_3miniwebp}`,
        miniavif: `${case6_3miniavif}`,
        bigwebp: `${case6_3webp}`,
        bigavif: `${case6_3avif}`,
      },
      {
        number: 4,
        key: "4f",
        mini: `${case6_4mini}`,
        big: `${case6_4}`,
        miniwebp: `${case6_4miniwebp}`,
        miniavif: `${case6_4miniavif}`,
        bigwebp: `${case6_4webp}`,
        bigavif: `${case6_4avif}`,
      },
      {
        number: 5,
        key: "5f",
        mini: `${case6_5mini}`,
        big: `${case6_5}`,
        miniwebp: `${case6_5miniwebp}`,
        miniavif: `${case6_5miniavif}`,
        bigwebp: `${case6_5webp}`,
        bigavif: `${case6_5avif}`,
      },
      {
        number: 6,
        key: "6f",
        mini: `${case6_6mini}`,
        big: `${case6_6}`,
        miniwebp: `${case6_6miniwebp}`,
        miniavif: `${case6_6miniavif}`,
        bigwebp: `${case6_6webp}`,
        bigavif: `${case6_6avif}`,
      },
      {
        number: 7,
        key: "7f",
        mini: `${case6_7mini}`,
        big: `${case6_7}`,
        miniwebp: `${case6_7miniwebp}`,
        miniavif: `${case6_7miniavif}`,
        bigwebp: `${case6_7webp}`,
        bigavif: `${case6_7avif}`,
      },
      {
        number: 8,
        key: "8f",
        mini: `${case6_8mini}`,
        big: `${case6_8}`,
        miniwebp: `${case6_8miniwebp}`,
        miniavif: `${case6_8miniavif}`,
        bigwebp: `${case6_8webp}`,
        bigavif: `${case6_8avif}`,
      },
      {
        number: 9,
        key: "9f",
        mini: `${case6_9mini}`,
        big: `${case6_9}`,
        miniwebp: `${case6_9miniwebp}`,
        miniavif: `${case6_9miniavif}`,
        bigwebp: `${case6_9webp}`,
        bigavif: `${case6_9avif}`,
      },
      {
        number: 10,
        key: "10f",
        mini: `${case6_10mini}`,
        big: `${case6_10}`,
        miniwebp: `${case6_10miniwebp}`,
        miniavif: `${case6_10miniavif}`,
        bigwebp: `${case6_10webp}`,
        bigavif: `${case6_10avif}`,
      },
      {
        number: 11,
        key: "11f",
        mini: `${case6_11mini}`,
        big: `${case6_11}`,
        miniwebp: `${case6_11miniwebp}`,
        miniavif: `${case6_11miniavif}`,
        bigwebp: `${case6_11webp}`,
        bigavif: `${case6_11avif}`,
      },
      {
        number: 12,
        key: "12f",
        mini: `${case6_12mini}`,
        big: `${case6_12}`,
        miniwebp: `${case6_12miniwebp}`,
        miniavif: `${case6_12miniavif}`,
        bigwebp: `${case6_12webp}`,
        bigavif: `${case6_12avif}`,
      },
      {
        number: 13,
        key: "13f",
        mini: `${case6_13mini}`,
        big: `${case6_13}`,
        miniwebp: `${case6_13miniwebp}`,
        miniavif: `${case6_13miniavif}`,
        bigwebp: `${case6_13webp}`,
        bigavif: `${case6_13avif}`,
      },
      {
        number: 14,
        key: "14f",
        mini: `${case6_14mini}`,
        big: `${case6_14}`,
        miniwebp: `${case6_14miniwebp}`,
        miniavif: `${case6_14miniavif}`,
        bigwebp: `${case6_14webp}`,
        bigavif: `${case6_14avif}`,
      },
      {
        number: 15,
        key: "15f",
        mini: `${case6_15mini}`,
        big: `${case6_15}`,
        miniwebp: `${case6_15miniwebp}`,
        miniavif: `${case6_15miniavif}`,
        bigwebp: `${case6_15webp}`,
        bigavif: `${case6_15avif}`,
      },
      {
        number: 16,
        key: "16f",
        mini: `${case6_16mini}`,
        big: `${case6_16}`,
        miniwebp: `${case6_16miniwebp}`,
        miniavif: `${case6_16miniavif}`,
        bigwebp: `${case6_16webp}`,
        bigavif: `${case6_16avif}`,
      },
      {
        number: 17,
        key: "17f",
        mini: `${case6_17mini}`,
        big: `${case6_17}`,
        miniwebp: `${case6_17miniwebp}`,
        miniavif: `${case6_17miniavif}`,
        bigwebp: `${case6_17webp}`,
        bigavif: `${case6_17avif}`,
      },
      {
        number: 18,
        key: "18f",
        mini: `${case6_18mini}`,
        big: `${case6_18}`,
        miniwebp: `${case6_18miniwebp}`,
        miniavif: `${case6_18miniavif}`,
        bigwebp: `${case6_18webp}`,
        bigavif: `${case6_18avif}`,
      },
      {
        number: 19,
        key: "19f",
        mini: `${case6_19mini}`,
        big: `${case6_19}`,
        miniwebp: `${case6_19miniwebp}`,
        miniavif: `${case6_19miniavif}`,
        bigwebp: `${case6_19webp}`,
        bigavif: `${case6_19avif}`,
      },
      {
        number: 20,
        key: "20f",
        mini: `${case6_20mini}`,
        big: `${case6_20}`,
        miniwebp: `${case6_20miniwebp}`,
        miniavif: `${case6_20miniavif}`,
        bigwebp: `${case6_20webp}`,
        bigavif: `${case6_20avif}`,
      },
      {
        number: 21,
        key: "21f",
        mini: `${case6_21mini}`,
        big: `${case6_21}`,
        miniwebp: `${case6_21miniwebp}`,
        miniavif: `${case6_21miniavif}`,
        bigwebp: `${case6_21webp}`,
        bigavif: `${case6_21avif}`,
      },
      {
        number: 22,
        key: "22f",
        mini: `${case6_22mini}`,
        big: `${case6_22}`,
        miniwebp: `${case6_22miniwebp}`,
        miniavif: `${case6_22miniavif}`,
        bigwebp: `${case6_22webp}`,
        bigavif: `${case6_22avif}`,
      },
      {
        number: 23,
        key: "23f",
        mini: `${case6_23mini}`,
        big: `${case6_23}`,
        miniwebp: `${case6_23miniwebp}`,
        miniavif: `${case6_23miniavif}`,
        bigwebp: `${case6_23webp}`,
        bigavif: `${case6_23avif}`,
      },
      {
        number: 24,
        key: "24f",
        mini: `${case6_24mini}`,
        big: `${case6_24}`,
        miniwebp: `${case6_24miniwebp}`,
        miniavif: `${case6_24miniavif}`,
        bigwebp: `${case6_24webp}`,
        bigavif: `${case6_24avif}`,
      },
      {
        number: 25,
        key: "25f",
        mini: `${case6_25mini}`,
        big: `${case6_25}`,
        miniwebp: `${case6_25miniwebp}`,
        miniavif: `${case6_25miniavif}`,
        bigwebp: `${case6_25webp}`,
        bigavif: `${case6_25avif}`,
      },
      {
        number: 26,
        key: "26f",
        mini: `${case6_26mini}`,
        big: `${case6_26}`,
        miniwebp: `${case6_26miniwebp}`,
        miniavif: `${case6_26miniavif}`,
        bigwebp: `${case6_26webp}`,
        bigavif: `${case6_26avif}`,
      },
      {
        number: 27,
        key: "27f",
        mini: `${case6_27mini}`,
        big: `${case6_27}`,
        miniwebp: `${case6_27miniwebp}`,
        miniavif: `${case6_27miniavif}`,
        bigwebp: `${case6_27webp}`,
        bigavif: `${case6_27avif}`,
      },
      {
        number: 28,
        key: "28f",
        mini: `${case6_28mini}`,
        big: `${case6_28}`,
        miniwebp: `${case6_28miniwebp}`,
        miniavif: `${case6_28miniavif}`,
        bigwebp: `${case6_28webp}`,
        bigavif: `${case6_28avif}`,
      },
      {
        number: 29,
        key: "29f",
        mini: `${case6_29mini}`,
        big: `${case6_29}`,
        miniwebp: `${case6_29miniwebp}`,
        miniavif: `${case6_29miniavif}`,
        bigwebp: `${case6_29webp}`,
        bigavif: `${case6_29avif}`,
      },
      {
        number: 30,
        key: "30f",
        mini: `${case6_30mini}`,
        big: `${case6_30}`,
        miniwebp: `${case6_30miniwebp}`,
        miniavif: `${case6_30miniavif}`,
        bigwebp: `${case6_30webp}`,
        bigavif: `${case6_30avif}`,
      },
      {
        number: 31,
        key: "31f",
        mini: `${case6_31mini}`,
        big: `${case6_31}`,
        miniwebp: `${case6_31miniwebp}`,
        miniavif: `${case6_31miniavif}`,
        bigwebp: `${case6_31webp}`,
        bigavif: `${case6_31avif}`,
      },
      {
        number: 32,
        key: "32f",
        mini: `${case6_32mini}`,
        big: `${case6_32}`,
        miniwebp: `${case6_32miniwebp}`,
        miniavif: `${case6_32miniavif}`,
        bigwebp: `${case6_32webp}`,
        bigavif: `${case6_32avif}`,
      },
      {
        number: 33,
        key: "33f",
        mini: `${case6_33mini}`,
        big: `${case6_33}`,
        miniwebp: `${case6_33miniwebp}`,
        miniavif: `${case6_33miniavif}`,
        bigwebp: `${case6_33webp}`,
        bigavif: `${case6_33avif}`,
      },
      {
        number: 34,
        key: "34f",
        mini: `${case6_34mini}`,
        big: `${case6_34}`,
        miniwebp: `${case6_34miniwebp}`,
        miniavif: `${case6_34miniavif}`,
        bigwebp: `${case6_34webp}`,
        bigavif: `${case6_34avif}`,
      },
      {
        number: 35,
        key: "35f",
        mini: `${case6_35mini}`,
        big: `${case6_35}`,
        miniwebp: `${case6_35miniwebp}`,
        miniavif: `${case6_35miniavif}`,
        bigwebp: `${case6_35webp}`,
        bigavif: `${case6_35avif}`,
      },
      {
        number: 36,
        key: "36f",
        mini: `${case6_36mini}`,
        big: `${case6_36}`,
        miniwebp: `${case6_36miniwebp}`,
        miniavif: `${case6_36miniavif}`,
        bigwebp: `${case6_36webp}`,
        bigavif: `${case6_36avif}`,
      },
      {
        number: 37,
        key: "37f",
        mini: `${case6_37mini}`,
        big: `${case6_37}`,
        miniwebp: `${case6_37miniwebp}`,
        miniavif: `${case6_37miniavif}`,
        bigwebp: `${case6_37webp}`,
        bigavif: `${case6_37avif}`,
      },
      {
        number: 38,
        key: "38f",
        mini: `${case6_38mini}`,
        big: `${case6_38}`,
        miniwebp: `${case6_38miniwebp}`,
        miniavif: `${case6_38miniavif}`,
        bigwebp: `${case6_38webp}`,
        bigavif: `${case6_38avif}`,
      },
      {
        number: 39,
        key: "39f",
        mini: `${case6_39mini}`,
        big: `${case6_39}`,
        miniwebp: `${case6_39miniwebp}`,
        miniavif: `${case6_39miniavif}`,
        bigwebp: `${case6_39webp}`,
        bigavif: `${case6_39avif}`,
      },
      {
        number: 40,
        key: "40f",
        mini: `${case6_40mini}`,
        big: `${case6_40}`,
        miniwebp: `${case6_40miniwebp}`,
        miniavif: `${case6_40miniavif}`,
        bigwebp: `${case6_40webp}`,
        bigavif: `${case6_40avif}`,
      },
      {
        number: 41,
        key: "41f",
        mini: `${case6_41mini}`,
        big: `${case6_41}`,
        miniwebp: `${case6_41miniwebp}`,
        miniavif: `${case6_41miniavif}`,
        bigwebp: `${case6_41webp}`,
        bigavif: `${case6_41avif}`,
      },
      {
        number: 42,
        key: "42f",
        mini: `${case6_42mini}`,
        big: `${case6_42}`,
        miniwebp: `${case6_42miniwebp}`,
        miniavif: `${case6_42miniavif}`,
        bigwebp: `${case6_42webp}`,
        bigavif: `${case6_42avif}`,
      },
      {
        number: 43,
        key: "43f",
        mini: `${case6_43mini}`,
        big: `${case6_43}`,
        miniwebp: `${case6_43miniwebp}`,
        miniavif: `${case6_43miniavif}`,
        bigwebp: `${case6_43webp}`,
        bigavif: `${case6_43avif}`,
      },
      {
        number: 44,
        key: "44f",
        mini: `${case6_44mini}`,
        big: `${case6_44}`,
        miniwebp: `${case6_44miniwebp}`,
        miniavif: `${case6_44miniavif}`,
        bigwebp: `${case6_44webp}`,
        bigavif: `${case6_44avif}`,
      },
    ],
    title: "Дом из монолита, 260 м2, Поселок Сиверский",
    text: [
      {
        key: "1f",
        title: "Отопление:",
        subtitle: [
          "Цокольный этаж:",
          "На этом этаже находится котельная. Отопление - радиаторы с нижним подключением, разводка труб тройниковым способом.",
          "1 этаж:",
          "92 м2 водяной теплый пол на маты 50 мм, 7 контуров.",
          "Коллектор тп располагается в кухне, стояки смонтированы от котельной.",
          "2 этаж: ",
          "96 м2. Смонтированы радиаторы с нижним подключением, подключение со стены хромированными трубками.",
          "Коллектор располагается в санузле, стояки к нему смонтированы от котельной.",
          "1 спальня находится над открытым крыльцом, и поэтому мы сделали в этой спальне водяной теплый пол через Унибокс.",
          "Заказчик прямо из спальни сможет регулировать температуру.",
        ],
      },
      {
        key: "2f",
        title: "Водоснабжение и канализация:",
        subtitle: [
          "Выполнена разводка труб водоснабжения и канализации на 2 полноценных санузла и кухню.",
          "Разводка выполнена коллекторным способом и рециркуляцией ГВС.",
          "Смонтированы стояки к коллекторам водоснабжения.",
          "Заказчик будет получать горячую воду моментально и крана.",
          "Трубы водоснабжения и канализации спрятаны в тело пола и стен, что дает максимально возможный эстетический вид после отделки помещений",
          "В санузле 2 этажа сделали водяной теплый пол через Унибокс.",
        ],
      },
      {
        key: "3f",
        title: "Котельная:",
        subtitle: [
          "Основной источник тепла электрический котел Protherm 14 КВт.",
          "Смонтированы гидрострелка и 2 насосных группы (Теплый пол и радиаторы).",
          "Так же установлена Буферная емкость S-tank 750 л.",
          "Всей системой управляет отопительный контроллер Zont 2000+.",
          "Принцип работы системы отопления, следующий: ночью котел греет буферную емкость по ночному тарифу до максимальной температуры 85 гр., днем теплые полы потребляют тепло из буферной емкости, котел при этом не работает. 90% времени тепла в буферной емкости хватает на весь день, в зависимости от температуры на улице. Если днем температура в буферной емкости упала меньше, чем 35 градусов, то начинает работать электрический котел и догревать систему отопления напрямую. Ночью все повторяется. Такая система позволяет экономить на электричестве до 70%.",
          "Звучит сложно, для заказчика в смартфоне всего несколько кнопок для управления режимами и желаемой температурой на этажах.",
          "Гидравлическая обвязка котельной выполнена из меди.",
          "За горячую воду отвечает бойлер косвенного нагрева Stout 200 л. , который греется напрямую от котла, так же управляется со смартфона.",
          "За подачу воды из скважины отвечает насосная станция Dab Easy box Mini. Которая сохраняет хорошее давление воды, при одновременном использование множества сантехнических приборов.",
          "Выполнен забор воды для анализа. На основе анализа подобрали и смонтировали фильтрацию.",
        ],
      },
    ],
    type: "монолит",
  },
  {
    key: 7,
    srcMain: `${case7_1}`,
    srcMainWebp: `${case7_1webp}`,
    srcMainAvif: `${case7_1avif}`,
    src: [
      {
        number: 1,
        key: "1g",
        mini: `${case7_1mini}`,
        big: `${case7_1}`,
        miniwebp: `${case7_1miniwebp}`,
        miniavif: `${case7_1miniavif}`,
        bigwebp: `${case7_1webp}`,
        bigavif: `${case7_1avif}`,
      },
      {
        number: 2,
        key: "2g",
        mini: `${case7_2mini}`,
        big: `${case7_2}`,
        miniwebp: `${case7_2miniwebp}`,
        miniavif: `${case7_2miniavif}`,
        bigwebp: `${case7_2webp}`,
        bigavif: `${case7_2avif}`,
      },
      {
        number: 3,
        key: "3g",
        mini: `${case7_3mini}`,
        big: `${case7_3}`,
        miniwebp: `${case7_3miniwebp}`,
        miniavif: `${case7_3miniavif}`,
        bigwebp: `${case7_3webp}`,
        bigavif: `${case7_3avif}`,
      },
      {
        number: 4,
        key: "4g",
        mini: `${case7_4mini}`,
        big: `${case7_4}`,
        miniwebp: `${case7_4miniwebp}`,
        miniavif: `${case7_4miniavif}`,
        bigwebp: `${case7_4webp}`,
        bigavif: `${case7_4avif}`,
      },
      {
        number: 5,
        key: "5g",
        mini: `${case7_5mini}`,
        big: `${case7_5}`,
        miniwebp: `${case7_5miniwebp}`,
        miniavif: `${case7_5miniavif}`,
        bigwebp: `${case7_5webp}`,
        bigavif: `${case7_5avif}`,
      },
      {
        number: 6,
        key: "6g",
        mini: `${case7_6mini}`,
        big: `${case7_6}`,
        miniwebp: `${case7_6miniwebp}`,
        miniavif: `${case7_6miniavif}`,
        bigwebp: `${case7_6webp}`,
        bigavif: `${case7_6avif}`,
      },
      {
        number: 7,
        key: "7g",
        mini: `${case7_7mini}`,
        big: `${case7_7}`,
        miniwebp: `${case7_7miniwebp}`,
        miniavif: `${case7_7miniavif}`,
        bigwebp: `${case7_7webp}`,
        bigavif: `${case7_7avif}`,
      },
      {
        number: 8,
        key: "8g",
        mini: `${case7_8mini}`,
        big: `${case7_8}`,
        miniwebp: `${case7_8miniwebp}`,
        miniavif: `${case7_8miniavif}`,
        bigwebp: `${case7_8webp}`,
        bigavif: `${case7_8avif}`,
      },
      {
        number: 9,
        key: "9g",
        mini: `${case7_9mini}`,
        big: `${case7_9}`,
        miniwebp: `${case7_9miniwebp}`,
        miniavif: `${case7_9miniavif}`,
        bigwebp: `${case7_9webp}`,
        bigavif: `${case7_9avif}`,
      },
      {
        number: 10,
        key: "10g",
        mini: `${case7_10mini}`,
        big: `${case7_10}`,
        miniwebp: `${case7_10miniwebp}`,
        miniavif: `${case7_10miniavif}`,
        bigwebp: `${case7_10webp}`,
        bigavif: `${case7_10avif}`,
      },
      {
        number: 11,
        key: "11g",
        mini: `${case7_11mini}`,
        big: `${case7_11}`,
        miniwebp: `${case7_11miniwebp}`,
        miniavif: `${case7_11miniavif}`,
        bigwebp: `${case7_11webp}`,
        bigavif: `${case7_11avif}`,
      },
      {
        number: 12,
        key: "12g",
        mini: `${case7_12mini}`,
        big: `${case7_12}`,
        miniwebp: `${case7_12miniwebp}`,
        miniavif: `${case7_12miniavif}`,
        bigwebp: `${case7_12webp}`,
        bigavif: `${case7_12avif}`,
      },
      {
        number: 13,
        key: "13g",
        mini: `${case7_13mini}`,
        big: `${case7_13}`,
        miniwebp: `${case7_13miniwebp}`,
        miniavif: `${case7_13miniavif}`,
        bigwebp: `${case7_13webp}`,
        bigavif: `${case7_13avif}`,
      },
      {
        number: 14,
        key: "14g",
        mini: `${case7_14mini}`,
        big: `${case7_14}`,
        miniwebp: `${case7_14miniwebp}`,
        miniavif: `${case7_14miniavif}`,
        bigwebp: `${case7_14webp}`,
        bigavif: `${case7_14avif}`,
      },
      {
        number: 15,
        key: "15g",
        mini: `${case7_15mini}`,
        big: `${case7_15}`,
        miniwebp: `${case7_15miniwebp}`,
        miniavif: `${case7_15miniavif}`,
        bigwebp: `${case7_15webp}`,
        bigavif: `${case7_15avif}`,
      },
      {
        number: 16,
        key: "16g",
        mini: `${case7_16mini}`,
        big: `${case7_16}`,
        miniwebp: `${case7_16miniwebp}`,
        miniavif: `${case7_16miniavif}`,
        bigwebp: `${case7_16webp}`,
        bigavif: `${case7_16avif}`,
      },
      {
        number: 17,
        key: "17g",
        mini: `${case7_17mini}`,
        big: `${case7_17}`,
        miniwebp: `${case7_17miniwebp}`,
        miniavif: `${case7_17miniavif}`,
        bigwebp: `${case7_17webp}`,
        bigavif: `${case7_17avif}`,
      },
      {
        number: 18,
        key: "18g",
        mini: `${case7_18mini}`,
        big: `${case7_18}`,
        miniwebp: `${case7_18miniwebp}`,
        miniavif: `${case7_18miniavif}`,
        bigwebp: `${case7_18webp}`,
        bigavif: `${case7_18avif}`,
      },
      {
        number: 19,
        key: "19g",
        mini: `${case7_19mini}`,
        big: `${case7_19}`,
        miniwebp: `${case7_19miniwebp}`,
        miniavif: `${case7_19miniavif}`,
        bigwebp: `${case7_19webp}`,
        bigavif: `${case7_19avif}`,
      },
      {
        number: 20,
        key: "20g",
        mini: `${case7_20mini}`,
        big: `${case7_20}`,
        miniwebp: `${case7_20miniwebp}`,
        miniavif: `${case7_20miniavif}`,
        bigwebp: `${case7_20webp}`,
        bigavif: `${case7_20avif}`,
      },
    ],
    title: "Дом из бруса, 102 м2, КП Ромашки",
    text: [
      {
        key: "1g",
        title: "Отопление:",
        subtitle: [
          "1 этаж:",
          "50 м2 теплого пола с матами. 4 контура.",
          "Предварительно уложили слой утеплителя пеноплекс 50 мм.",
          "Утепление под трубой 100 мм. Общая толщина пирога со стяжкой 170 мм.",
          "Выполнен монтаж полусухой стяжки пола.",
        ],
      },
      {
        key: "2g",
        title: "Водоснабжение и канализация:",
        subtitle: [
          "Разводка водоснабжения и канализации на санузлы 1 и 2 этажей, и кухню.",
          "Разводка выполнена с помощью тройниковой системы из полипропиленовых труб.",
          "Трубы частично спрятаны в стяжку и стены, в дальнейшем скроются плиткой.",
          "Смонтирован вводной узел ХВС. С установкой магистрального фильтров.",
        ],
      },
      {
        key: "3g",
        title: "Котельная:",
        subtitle: [
          "Смонтирован электрический котел Protherm Skat 9 КВт.",
          "Установлен и настроен GSM модуль Zont. Котел управляется с помощью с помощью смартфона. Что позволяет значительно экономить электроэнергию.",
          "Смонтирован бойлер косвенного нагрева Stout 150 л., на специальную подставку.",
          "Бойлер подключен к котлу через трехходовой клапан Fugas. Время нагрева бойлера до 50-60 гр. 40 мин.",
          "Теплый пол работают напрямую от котлового насоса.",
          "Гидравлическая обвязка котельной выполнена полипропиленом.",
        ],
      },
    ],
    type: "брус",
  },
  {
    key: 8,
    srcMain: `${case8_1}`,
    srcMainWebp: `${case8_1webp}`,
    srcMainAvif: `${case8_1avif}`,
    src: [
      {
        number: 1,
        key: "1h",
        mini: `${case8_1mini}`,
        big: `${case8_1}`,
        miniwebp: `${case8_1miniwebp}`,
        miniavif: `${case8_1miniavif}`,
        bigwebp: `${case8_1webp}`,
        bigavif: `${case8_1avif}`,
      },
      {
        number: 2,
        key: "2h",
        mini: `${case8_2mini}`,
        big: `${case8_2}`,
        miniwebp: `${case8_2miniwebp}`,
        miniavif: `${case8_2miniavif}`,
        bigwebp: `${case8_2webp}`,
        bigavif: `${case8_2avif}`,
      },
      {
        number: 3,
        key: "3h",
        mini: `${case8_3mini}`,
        big: `${case8_3}`,
        miniwebp: `${case8_3miniwebp}`,
        miniavif: `${case8_3miniavif}`,
        bigwebp: `${case8_3webp}`,
        bigavif: `${case8_3avif}`,
      },
      {
        number: 4,
        key: "4h",
        mini: `${case8_4mini}`,
        big: `${case8_4}`,
        miniwebp: `${case8_4miniwebp}`,
        miniavif: `${case8_4miniavif}`,
        bigwebp: `${case8_4webp}`,
        bigavif: `${case8_4avif}`,
      },
      {
        number: 5,
        key: "5h",
        mini: `${case8_5mini}`,
        big: `${case8_5}`,
        miniwebp: `${case8_5miniwebp}`,
        miniavif: `${case8_5miniavif}`,
        bigwebp: `${case8_5webp}`,
        bigavif: `${case8_5avif}`,
      },
      {
        number: 6,
        key: "6h",
        mini: `${case8_6mini}`,
        big: `${case8_6}`,
        miniwebp: `${case8_6miniwebp}`,
        miniavif: `${case8_6miniavif}`,
        bigwebp: `${case8_6webp}`,
        bigavif: `${case8_6avif}`,
      },
      {
        number: 7,
        key: "7h",
        mini: `${case8_7mini}`,
        big: `${case8_7}`,
        miniwebp: `${case8_7miniwebp}`,
        miniavif: `${case8_7miniavif}`,
        bigwebp: `${case8_7webp}`,
        bigavif: `${case8_7avif}`,
      },
      {
        number: 8,
        key: "8h",
        mini: `${case8_8mini}`,
        big: `${case8_8}`,
        miniwebp: `${case8_8miniwebp}`,
        miniavif: `${case8_8miniavif}`,
        bigwebp: `${case8_8webp}`,
        bigavif: `${case8_8avif}`,
      },
      {
        number: 9,
        key: "9h",
        mini: `${case8_9mini}`,
        big: `${case8_9}`,
        miniwebp: `${case8_9miniwebp}`,
        miniavif: `${case8_9miniavif}`,
        bigwebp: `${case8_9webp}`,
        bigavif: `${case8_9avif}`,
      },
      {
        number: 10,
        key: "10h",
        mini: `${case8_10mini}`,
        big: `${case8_10}`,
        miniwebp: `${case8_10miniwebp}`,
        miniavif: `${case8_10miniavif}`,
        bigwebp: `${case8_10webp}`,
        bigavif: `${case8_10avif}`,
      },
      {
        number: 11,
        key: "11h",
        mini: `${case8_11mini}`,
        big: `${case8_11}`,
        miniwebp: `${case8_11miniwebp}`,
        miniavif: `${case8_11miniavif}`,
        bigwebp: `${case8_11webp}`,
        bigavif: `${case8_11avif}`,
      },
      {
        number: 12,
        key: "12h",
        mini: `${case8_12mini}`,
        big: `${case8_12}`,
        miniwebp: `${case8_12miniwebp}`,
        miniavif: `${case8_12miniavif}`,
        bigwebp: `${case8_12webp}`,
        bigavif: `${case8_12avif}`,
      },
      {
        number: 13,
        key: "13h",
        mini: `${case8_13mini}`,
        big: `${case8_13}`,
        miniwebp: `${case8_13miniwebp}`,
        miniavif: `${case8_13miniavif}`,
        bigwebp: `${case8_13webp}`,
        bigavif: `${case8_13avif}`,
      },
      {
        number: 14,
        key: "14h",
        mini: `${case8_14mini}`,
        big: `${case8_14}`,
        miniwebp: `${case8_14miniwebp}`,
        miniavif: `${case8_14miniavif}`,
        bigwebp: `${case8_14webp}`,
        bigavif: `${case8_14avif}`,
      },
      {
        number: 15,
        key: "15h",
        mini: `${case8_15mini}`,
        big: `${case8_15}`,
        miniwebp: `${case8_15miniwebp}`,
        miniavif: `${case8_15miniavif}`,
        bigwebp: `${case8_15webp}`,
        bigavif: `${case8_15avif}`,
      },
      {
        number: 16,
        key: "16h",
        mini: `${case8_16mini}`,
        big: `${case8_16}`,
        miniwebp: `${case8_16miniwebp}`,
        miniavif: `${case8_16miniavif}`,
        bigwebp: `${case8_16webp}`,
        bigavif: `${case8_16avif}`,
      },
      {
        number: 17,
        key: "17h",
        mini: `${case8_17mini}`,
        big: `${case8_17}`,
        miniwebp: `${case8_17miniwebp}`,
        miniavif: `${case8_17miniavif}`,
        bigwebp: `${case8_17webp}`,
        bigavif: `${case8_17avif}`,
      },
      {
        number: 18,
        key: "18h",
        mini: `${case8_18mini}`,
        big: `${case8_18}`,
        miniwebp: `${case8_18miniwebp}`,
        miniavif: `${case8_18miniavif}`,
        bigwebp: `${case8_18webp}`,
        bigavif: `${case8_18avif}`,
      },
      {
        number: 19,
        key: "19h",
        mini: `${case8_19mini}`,
        big: `${case8_19}`,
        miniwebp: `${case8_19miniwebp}`,
        miniavif: `${case8_19miniavif}`,
        bigwebp: `${case8_19webp}`,
        bigavif: `${case8_19avif}`,
      },
      {
        number: 20,
        key: "20h",
        mini: `${case8_20mini}`,
        big: `${case8_20}`,
        miniwebp: `${case8_20miniwebp}`,
        miniavif: `${case8_20miniavif}`,
        bigwebp: `${case8_20webp}`,
        bigavif: `${case8_20avif}`,
      },
      {
        number: 21,
        key: "21h",
        mini: `${case8_21mini}`,
        big: `${case8_21}`,
        miniwebp: `${case8_21miniwebp}`,
        miniavif: `${case8_21miniavif}`,
        bigwebp: `${case8_21webp}`,
        bigavif: `${case8_21avif}`,
      },
      {
        number: 22,
        key: "22h",
        mini: `${case8_22mini}`,
        big: `${case8_22}`,
        miniwebp: `${case8_22miniwebp}`,
        miniavif: `${case8_22miniavif}`,
        bigwebp: `${case8_22webp}`,
        bigavif: `${case8_22avif}`,
      },
      {
        number: 23,
        key: "23h",
        mini: `${case8_23mini}`,
        big: `${case8_23}`,
        miniwebp: `${case8_23miniwebp}`,
        miniavif: `${case8_23miniavif}`,
        bigwebp: `${case8_23webp}`,
        bigavif: `${case8_23avif}`,
      },
      {
        number: 24,
        key: "24h",
        mini: `${case8_24mini}`,
        big: `${case8_24}`,
        miniwebp: `${case8_24miniwebp}`,
        miniavif: `${case8_24miniavif}`,
        bigwebp: `${case8_24webp}`,
        bigavif: `${case8_24avif}`,
      },
      {
        number: 25,
        key: "25h",
        mini: `${case8_25mini}`,
        big: `${case8_25}`,
        miniwebp: `${case8_25miniwebp}`,
        miniavif: `${case8_25miniavif}`,
        bigwebp: `${case8_25webp}`,
        bigavif: `${case8_25avif}`,
      },
      {
        number: 26,
        key: "26h",
        mini: `${case8_26mini}`,
        big: `${case8_26}`,
        miniwebp: `${case8_26miniwebp}`,
        miniavif: `${case8_26miniavif}`,
        bigwebp: `${case8_26webp}`,
        bigavif: `${case8_26avif}`,
      },
      {
        number: 27,
        key: "27h",
        mini: `${case8_27mini}`,
        big: `${case8_27}`,
        miniwebp: `${case8_27miniwebp}`,
        miniavif: `${case8_27miniavif}`,
        bigwebp: `${case8_27webp}`,
        bigavif: `${case8_27avif}`,
      },
      {
        number: 28,
        key: "28h",
        mini: `${case8_28mini}`,
        big: `${case8_28}`,
        miniwebp: `${case8_28miniwebp}`,
        miniavif: `${case8_28miniavif}`,
        bigwebp: `${case8_28webp}`,
        bigavif: `${case8_28avif}`,
      },
      {
        number: 29,
        key: "29h",
        mini: `${case8_29mini}`,
        big: `${case8_29}`,
        miniwebp: `${case8_29miniwebp}`,
        miniavif: `${case8_29miniavif}`,
        bigwebp: `${case8_29webp}`,
        bigavif: `${case8_29avif}`,
      },
      {
        number: 30,
        key: "30h",
        mini: `${case8_30mini}`,
        big: `${case8_30}`,
        miniwebp: `${case8_30miniwebp}`,
        miniavif: `${case8_30miniavif}`,
        bigwebp: `${case8_30webp}`,
        bigavif: `${case8_30avif}`,
      },
    ],
    title: "Дом из газобетона, 280 м2, КП Кавголовское озеро",
    text: [
      {
        key: "1h",
        title: "Отопление:",
        subtitle: [
          "1 этаж:",
          "112 м2 теплого пола с матами. 11 контуров.",
          "Дополнительно утепление +200 мм.",
          "Общая толщина пирога со стяжкой 320 мм.",
          "Выполнен монтаж полусухой стяжки пола.",
          "2 этаж и цоколь:",
          "Выполнен монтаж радиаторов Buderus с нижним подключением 11 шт.",
          "Коллекторная система. Трубы металлополимерные.",
        ],
      },
      {
        key: "2h",
        title: "Котельная:",
        subtitle: [
          "Смонтирован Газовый котел Buderus U072 – 35 КВт.",
          "Смонтирован бойлер косвенного нагрева Stout 300 л., на специальную подставку.",
          "Бойлер подключен к котлу через трехходовой клапан Fugas. Время нагрева бойлера до 50-60 гр. 40 мин.",
          "На теплый пол смонтирован узел подмеса Tim JH 1036 с насосом.",
          "Гидравлическая обвязка котельной выполнена полипропиленом.",
          "Пуско-наладочные работы выполнены сервисным мастером. Котел поставлен на официальную гарантию.",
        ],
      },
    ],
    type: "газобетон1",
  },
  {
    key: 9,
    srcMain: `${case9_1}`,
    srcMainWebp: `${case9_1webp}`,
    srcMainAvif: `${case9_1avif}`,
    src: [
      {
        number: 1,
        key: "1i",
        mini: `${case9_1mini}`,
        big: `${case9_1}`,
        miniwebp: `${case9_1miniwebp}`,
        miniavif: `${case9_1miniavif}`,
        bigwebp: `${case9_1webp}`,
        bigavif: `${case9_1avif}`,
      },
      {
        number: 2,
        key: "2i",
        mini: `${case9_2mini}`,
        big: `${case9_2}`,
        miniwebp: `${case9_2miniwebp}`,
        miniavif: `${case9_2miniavif}`,
        bigwebp: `${case9_2webp}`,
        bigavif: `${case9_2avif}`,
      },
      {
        number: 3,
        key: "3i",
        mini: `${case9_3mini}`,
        big: `${case9_3}`,
        miniwebp: `${case9_3miniwebp}`,
        miniavif: `${case9_3miniavif}`,
        bigwebp: `${case9_3webp}`,
        bigavif: `${case9_3avif}`,
      },
      {
        number: 4,
        key: "4i",
        mini: `${case9_4mini}`,
        big: `${case9_4}`,
        miniwebp: `${case9_4miniwebp}`,
        miniavif: `${case9_4miniavif}`,
        bigwebp: `${case9_4webp}`,
        bigavif: `${case9_4avif}`,
      },
      {
        number: 5,
        key: "5i",
        mini: `${case9_5mini}`,
        big: `${case9_5}`,
        miniwebp: `${case9_5miniwebp}`,
        miniavif: `${case9_5miniavif}`,
        bigwebp: `${case9_5webp}`,
        bigavif: `${case9_5avif}`,
      },
      {
        number: 6,
        key: "6i",
        mini: `${case9_6mini}`,
        big: `${case9_6}`,
        miniwebp: `${case9_6miniwebp}`,
        miniavif: `${case9_6miniavif}`,
        bigwebp: `${case9_6webp}`,
        bigavif: `${case9_6avif}`,
      },
      {
        number: 7,
        key: "7i",
        mini: `${case9_7mini}`,
        big: `${case9_7}`,
        miniwebp: `${case9_7miniwebp}`,
        miniavif: `${case9_7miniavif}`,
        bigwebp: `${case9_7webp}`,
        bigavif: `${case9_7avif}`,
      },
      {
        number: 8,
        key: "8i",
        mini: `${case9_8mini}`,
        big: `${case9_8}`,
        miniwebp: `${case9_8miniwebp}`,
        miniavif: `${case9_8miniavif}`,
        bigwebp: `${case9_8webp}`,
        bigavif: `${case9_8avif}`,
      },
      {
        number: 9,
        key: "9i",
        mini: `${case9_9mini}`,
        big: `${case9_9}`,
        miniwebp: `${case9_9miniwebp}`,
        miniavif: `${case9_9miniavif}`,
        bigwebp: `${case9_9webp}`,
        bigavif: `${case9_9avif}`,
      },
      {
        number: 10,
        key: "10i",
        mini: `${case9_10mini}`,
        big: `${case9_10}`,
        miniwebp: `${case9_10miniwebp}`,
        miniavif: `${case9_10miniavif}`,
        bigwebp: `${case9_10webp}`,
        bigavif: `${case9_10avif}`,
      },
      {
        number: 11,
        key: "11i",
        mini: `${case9_11mini}`,
        big: `${case9_11}`,
        miniwebp: `${case9_11miniwebp}`,
        miniavif: `${case9_11miniavif}`,
        bigwebp: `${case9_11webp}`,
        bigavif: `${case9_11avif}`,
      },
      {
        number: 12,
        key: "12i",
        mini: `${case9_12mini}`,
        big: `${case9_12}`,
        miniwebp: `${case9_12miniwebp}`,
        miniavif: `${case9_12miniavif}`,
        bigwebp: `${case9_12webp}`,
        bigavif: `${case9_12avif}`,
      },
    ],
    title: "Дом из газобетона, 80 м2, КП Фауна",
    text: [
      {
        key: "1i",
        title: "Монтаж водяного теплого пола",
        subtitle: [
          "Выполнили монтаж водяного теплого пола на 1 этаже",
          "Провели пневматические испытания.",
          "Залили полусухую стяжку",
          "Срок выполнения: 2 рабочих дня.",
        ],
      },
    ],
    type: "газобетон2",
  },
];

import React from "react";
import img1 from "../images/contacts/1.jpeg";
import img1webp from "../images/contacts/1.webp";
import img1avif from "../images/contacts/1.avif";
import img2 from "../images/contacts/2.jpeg";
import img2webp from "../images/contacts/2.webp";
import img2avif from "../images/contacts/2.avif";
import img3 from "../images/contacts/3.jpeg";
import img3webp from "../images/contacts/3.webp";
import img3avif from "../images/contacts/3.avif";
import img4 from "../images/contacts/4.jpeg";
import img4webp from "../images/contacts/4.webp";
import img4avif from "../images/contacts/4.avif";
import img5 from "../images/contacts/5.jpeg";
import img5webp from "../images/contacts/5.webp";
import img5avif from "../images/contacts/5.avif";
import img6 from "../images/contacts/6.jpeg";
import img6webp from "../images/contacts/6.webp";
import img6avif from "../images/contacts/6.avif";
import img7 from "../images/contacts/7.jpeg";
import img7webp from "../images/contacts/7.webp";
import img7avif from "../images/contacts/7.avif";
import img8 from "../images/contacts/8.jpeg";
import img8webp from "../images/contacts/8.webp";
import img8avif from "../images/contacts/8.avif";
import img9 from "../images/contacts/9.jpeg";
import img9webp from "../images/contacts/9.webp";
import img9avif from "../images/contacts/9.avif";
import img10 from "../images/contacts/10.jpeg";
import img10webp from "../images/contacts/10.webp";
import img10avif from "../images/contacts/10.avif";
import img11 from "../images/contacts/11.jpeg";
import img11webp from "../images/contacts/11.webp";
import img11avif from "../images/contacts/11.avif";
import img12 from "../images/contacts/12.jpeg";
import img12webp from "../images/contacts/12.webp";
import img12avif from "../images/contacts/12.avif";
import img13 from "../images/contacts/13.jpeg";
import img13webp from "../images/contacts/13.webp";
import img13avif from "../images/contacts/13.avif";
import img14 from "../images/contacts/14.jpeg";
import img14webp from "../images/contacts/14.webp";
import img14avif from "../images/contacts/14.avif";
import img15 from "../images/contacts/15.jpeg";
import img15webp from "../images/contacts/15.webp";
import img15avif from "../images/contacts/15.avif";
import img16 from "../images/contacts/16.jpeg";
import img16webp from "../images/contacts/16.webp";
import img16avif from "../images/contacts/16.avif";
import img17 from "../images/contacts/17.jpeg";
import img17webp from "../images/contacts/17.webp";
import img17avif from "../images/contacts/17.avif";
import img18 from "../images/contacts/18.jpeg";
import img18webp from "../images/contacts/18.webp";
import img18avif from "../images/contacts/18.avif";
import img19 from "../images/contacts/19.jpeg";
import img19webp from "../images/contacts/19.webp";
import img19avif from "../images/contacts/19.avif";
import img20 from "../images/contacts/20.jpeg";
import img20webp from "../images/contacts/20.webp";
import img20avif from "../images/contacts/20.avif";

import img1mini from "../images/contacts/mini/1.jpeg";
import img1miniwebp from "../images/contacts/mini/1.webp";
import img1miniavif from "../images/contacts/mini/1.avif";
import img2mini from "../images/contacts/mini/2.jpeg";
import img2miniwebp from "../images/contacts/mini/2.webp";
import img2miniavif from "../images/contacts/mini/2.avif";
import img3mini from "../images/contacts/mini/3.jpeg";
import img3miniwebp from "../images/contacts/mini/3.webp";
import img3miniavif from "../images/contacts/mini/3.avif";
import img4mini from "../images/contacts/mini/4.jpeg";
import img4miniwebp from "../images/contacts/mini/4.webp";
import img4miniavif from "../images/contacts/mini/4.avif";
import img5mini from "../images/contacts/mini/5.jpeg";
import img5miniwebp from "../images/contacts/mini/5.webp";
import img5miniavif from "../images/contacts/mini/5.avif";
import img6mini from "../images/contacts/mini/6.jpeg";
import img6miniwebp from "../images/contacts/mini/6.webp";
import img6miniavif from "../images/contacts/mini/6.avif";
import img7mini from "../images/contacts/mini/7.jpeg";
import img7miniwebp from "../images/contacts/mini/7.webp";
import img7miniavif from "../images/contacts/mini/7.avif";
import img8mini from "../images/contacts/mini/8.jpeg";
import img8miniwebp from "../images/contacts/mini/8.webp";
import img8miniavif from "../images/contacts/mini/8.avif";
import img9mini from "../images/contacts/mini/9.jpeg";
import img9miniwebp from "../images/contacts/mini/9.webp";
import img9miniavif from "../images/contacts/mini/9.avif";
import img10mini from "../images/contacts/mini/10.jpeg";
import img10miniwebp from "../images/contacts/mini/10.webp";
import img10miniavif from "../images/contacts/mini/10.avif";
import img11mini from "../images/contacts/mini/11.jpeg";
import img11miniwebp from "../images/contacts/mini/11.webp";
import img11miniavif from "../images/contacts/mini/11.avif";
import img12mini from "../images/contacts/mini/12.jpeg";
import img12miniwebp from "../images/contacts/mini/12.webp";
import img12miniavif from "../images/contacts/mini/12.avif";
import img13mini from "../images/contacts/mini/13.jpeg";
import img13miniwebp from "../images/contacts/mini/13.webp";
import img13miniavif from "../images/contacts/mini/13.avif";
import img14mini from "../images/contacts/mini/14.jpeg";
import img14miniwebp from "../images/contacts/mini/14.webp";
import img14miniavif from "../images/contacts/mini/14.avif";
import img15mini from "../images/contacts/mini/15.jpeg";
import img15miniwebp from "../images/contacts/mini/15.webp";
import img15miniavif from "../images/contacts/mini/15.avif";
import img16mini from "../images/contacts/mini/16.jpeg";
import img16miniwebp from "../images/contacts/mini/16.webp";
import img16miniavif from "../images/contacts/mini/16.avif";
import img17mini from "../images/contacts/mini/17.jpeg";
import img17miniwebp from "../images/contacts/mini/17.webp";
import img17miniavif from "../images/contacts/mini/17.avif";
import img18mini from "../images/contacts/mini/18.jpeg";
import img18miniwebp from "../images/contacts/mini/18.webp";
import img18miniavif from "../images/contacts/mini/18.avif";
import img19mini from "../images/contacts/mini/19.jpeg";
import img19miniwebp from "../images/contacts/mini/19.webp";
import img19miniavif from "../images/contacts/mini/19.avif";
import img20mini from "../images/contacts/mini/20.jpeg";
import img20miniwebp from "../images/contacts/mini/20.webp";
import img20miniavif from "../images/contacts/mini/20.avif";

export const ContactsContext = React.createContext();

export const contact = [
  {
    key: 1,
    src: `${img1}`,
    webp: `${img1webp}`,
    avif: `${img1avif}`,
    mini: `${img1mini}`,
    miniwebp: `${img1miniwebp}`,
    miniavif: `${img1miniavif}`,
  },
  {
    key: 2,
    src: `${img2}`,
    webp: `${img2webp}`,
    avif: `${img2avif}`,
    mini: `${img2mini}`,
    miniwebp: `${img2miniwebp}`,
    miniavif: `${img2miniavif}`,
  },
  {
    key: 3,
    src: `${img3}`,
    webp: `${img3webp}`,
    avif: `${img3avif}`,
    mini: `${img3mini}`,
    miniwebp: `${img3miniwebp}`,
    miniavif: `${img3miniavif}`,
  },
  {
    key: 4,
    src: `${img4}`,
    webp: `${img4webp}`,
    avif: `${img4avif}`,
    mini: `${img4mini}`,
    miniwebp: `${img4miniwebp}`,
    miniavif: `${img4miniavif}`,
  },
  {
    key: 5,
    src: `${img5}`,
    webp: `${img5webp}`,
    avif: `${img5avif}`,
    mini: `${img5mini}`,
    miniwebp: `${img5miniwebp}`,
    miniavif: `${img5miniavif}`,
  },
  {
    key: 6,
    src: `${img6}`,
    webp: `${img6webp}`,
    avif: `${img6avif}`,
    mini: `${img6mini}`,
    miniwebp: `${img6miniwebp}`,
    miniavif: `${img6miniavif}`,
  },
  {
    key: 7,
    src: `${img7}`,
    webp: `${img7webp}`,
    avif: `${img7avif}`,
    mini: `${img7mini}`,
    miniwebp: `${img7miniwebp}`,
    miniavif: `${img7miniavif}`,
  },
  {
    key: 8,
    src: `${img8}`,
    webp: `${img8webp}`,
    avif: `${img8avif}`,
    mini: `${img8mini}`,
    miniwebp: `${img8miniwebp}`,
    miniavif: `${img8miniavif}`,
  },
  {
    key: 9,
    src: `${img9}`,
    webp: `${img9webp}`,
    avif: `${img9avif}`,
    mini: `${img9mini}`,
    miniwebp: `${img9miniwebp}`,
    miniavif: `${img9miniavif}`,
  },
  {
    key: 10,
    src: `${img10}`,
    webp: `${img10webp}`,
    avif: `${img10avif}`,
    mini: `${img10mini}`,
    miniwebp: `${img10miniwebp}`,
    miniavif: `${img10miniavif}`,
  },
  {
    key: 11,
    src: `${img11}`,
    webp: `${img11webp}`,
    avif: `${img11avif}`,
    mini: `${img11mini}`,
    miniwebp: `${img11miniwebp}`,
    miniavif: `${img11miniavif}`,
  },
  {
    key: 12,
    src: `${img12}`,
    webp: `${img12webp}`,
    avif: `${img12avif}`,
    mini: `${img12mini}`,
    miniwebp: `${img12miniwebp}`,
    miniavif: `${img12miniavif}`,
  },
  {
    key: 13,
    src: `${img13}`,
    webp: `${img13webp}`,
    avif: `${img13avif}`,
    mini: `${img13mini}`,
    miniwebp: `${img13miniwebp}`,
    miniavif: `${img13miniavif}`,
  },
  {
    key: 14,
    src: `${img14}`,
    webp: `${img14webp}`,
    avif: `${img14avif}`,
    mini: `${img14mini}`,
    miniwebp: `${img14miniwebp}`,
    miniavif: `${img14miniavif}`,
  },
  {
    key: 15,
    src: `${img15}`,
    webp: `${img15webp}`,
    avif: `${img15avif}`,
    mini: `${img15mini}`,
    miniwebp: `${img15miniwebp}`,
    miniavif: `${img15miniavif}`,
  },
  {
    key: 16,
    src: `${img16}`,
    webp: `${img16webp}`,
    avif: `${img16avif}`,
    mini: `${img16mini}`,
    miniwebp: `${img16miniwebp}`,
    miniavif: `${img16miniavif}`,
  },
  {
    key: 17,
    src: `${img17}`,
    webp: `${img17webp}`,
    avif: `${img17avif}`,
    mini: `${img17mini}`,
    miniwebp: `${img17miniwebp}`,
    miniavif: `${img17miniavif}`,
  },
  {
    key: 18,
    src: `${img18}`,
    webp: `${img18webp}`,
    avif: `${img18avif}`,
    mini: `${img18mini}`,
    miniwebp: `${img18miniwebp}`,
    miniavif: `${img18miniavif}`,
  },
  {
    key: 19,
    src: `${img19}`,
    webp: `${img19webp}`,
    avif: `${img19avif}`,
    mini: `${img19mini}`,
    miniwebp: `${img19miniwebp}`,
    miniavif: `${img19miniavif}`,
  },
  {
    key: 20,
    src: `${img20}`,
    webp: `${img20webp}`,
    avif: `${img20avif}`,
    mini: `${img20mini}`,
    miniwebp: `${img20miniwebp}`,
    miniavif: `${img20miniavif}`,
  },
];
